import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {routes} from "./router/routes";

import AdminPage from 'pages/admin/AdminPage';
import AdminAuthPage from 'pages/admin/auth/AdminAuth';

import HomePage from 'pages/home/HomePage';
import WorldPage from 'pages/world/WorldPage';
import CountryPage from 'pages/country/CountryPage';
import Page404 from 'pages/404';
import Page403 from 'pages/403';

import { UserProvider } from 'providers/UserProvider';
import ProtectedRoute from 'router/ProtectedRoute';

function App() {
  return (

    <UserProvider>
        <BrowserRouter>
          <Routes>
              <Route element={<Page404 />} path='*'/>
              <Route index element={<HomePage />} path={routes.HOME}/>
              <Route element={<AdminPage />} path={routes.ADMIN}/>
              <Route element={<AdminAuthPage />} path={routes.ADMIN_AUTH} />
              <Route element={<ProtectedRoute><WorldPage /></ProtectedRoute>} path={routes.WORLD}/>
              <Route element={<ProtectedRoute><CountryPage /></ProtectedRoute>} path={routes.COUNTRY}/>
              <Route element={<Page403 />} path={routes.FORBIDDEN}/>
          </Routes>
        </BrowserRouter>
    </UserProvider>
  );
}

export default App;
