import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import { KeycloakProvider } from './services/keycloak';
import App from './App';
import "./index.css";
import {store} from './store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(    
<KeycloakProvider>
    <Provider store={store}>
      <App />
    </Provider>
</KeycloakProvider>);