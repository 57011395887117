import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { deleteItem } from "./admin.thunks";
import { IAdminState } from "./admin.types";

export const deleteItemCases = (
    builder: ActionReducerMapBuilder<IAdminState>,
  ) => {
    builder.addCase(deleteItem.pending, (state, action) => {
      state.deleting[action.meta.arg?.entityName] = "loading";
      state.error = null;
    });
    builder.addCase(deleteItem.fulfilled, (state, action) => {
      state.deleting[action.meta.arg?.entityName] = "succeeded";
    });
    builder.addCase(deleteItem.rejected, (state, action) => {
      state.deleting[action.meta.arg?.entityName] = "failed";
      state.error = action.payload?.message;
    });
  };   