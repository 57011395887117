import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { TAppDispatch, TRootState } from "store";
import { IWorkGroup, IWorkGroupCreateData } from "./workGroup.types";
import { URL_WORK_GROUP, URL_WORK_GROUPS } from "./workGroup.api";
import apiCitizen from "helpers/apiCitizen";

export const getWorkGroupList = createAsyncThunk<
  AxiosResponse<IWorkGroup[]>,
  void,
  {
    dispatch: TAppDispatch;
    rejectValue: AxiosError;
    state: TRootState;
  }
>(
  "workGroup/getWorkGroupList",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
        return (await apiCitizen.get(URL_WORK_GROUPS)) as AxiosResponse<IWorkGroup[]>;
    } catch (error) {
        return rejectWithValue(error as AxiosError);
    } 
  },
);

export const createWorkGroup = createAsyncThunk<
  AxiosResponse<IWorkGroup>,
  IWorkGroupCreateData,
  {
    dispatch: TAppDispatch;
    rejectValue: AxiosError;
    state: TRootState;
  }
>(
  "workGroup/createWorkGroup",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
        return (await apiCitizen.post(URL_WORK_GROUPS, data)) as AxiosResponse<IWorkGroup>;
    } catch (error) {
        return rejectWithValue(error as AxiosError);
    } 
  },
);

export const updateWorkGroup = createAsyncThunk<
  AxiosResponse<IWorkGroup>,
  { data: IWorkGroupCreateData, id: number},
  {
    dispatch: TAppDispatch;
    rejectValue: AxiosError;
    state: TRootState;
  }
>(
  "workGroup/updateWorkGroup",
  async ({data, id}, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const url = URL_WORK_GROUP(id);
    try {
        return (await apiCitizen.patch(url, data)) as AxiosResponse<IWorkGroup>;
    } catch (error) {
        return rejectWithValue(error as AxiosError);
    } 
  },
);