import { IAuthAdminState } from "./authAdmin.types";

export const ADMIN_TOKEN_KEY = 'access_token';

export const initialState: IAuthAdminState = {
    loading: 'idle',
    error: null,
    authorized: false,
    token: null,
};

