import styles from "./draw.module.scss";

export const drawPhotoPin = (svg, defs, defName, onClick, transform, imgHref) => {
  const avatar_size = 30;

  const pattern = defs.append("svg:pattern")
    .attr("id", defName)
    .attr("width", avatar_size)
    .attr("height", avatar_size)
    .attr("patternUnits", "userSpaceOnUse")

  pattern.append("svg:image")
    .attr("xlink:href", imgHref)
    .attr("width", avatar_size)
    .attr("height", avatar_size)
    .attr("x", 0)
    .attr("y", 0);

  const group = svg.append("g")
    .attr("id", `group_${defName}`)
    .attr("class", styles.pin)
    .attr("transform", transform)
    .on("click", onClick);
  group.append("path")
    .attr("d", "M0 15.5549C0 25.3567 11.0308 35.9229 15.0546 39.4517C15.8884 40.1828 17.1138 40.1828 17.9476 39.4517C21.9692 35.9229 33 25.3567 33 15.5549C33 6.96415 25.6124 0 16.5 0C7.3876 0 0 6.96415 0 15.5549V15.5549Z")  

  group.append("circle")
    .attr("cx", avatar_size / 2 + 1.5)
    .attr("cy", avatar_size / 2 + 1.5)
    .attr("r", avatar_size / 2)
    .style("fill", "#fff")
    .style("fill", "url(#" + defName + ")")
    .on("click", onClick);
}