import {memo, useCallback, useEffect} from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router";
import { routes } from "router/routes";

import styles from "./HomePage.module.scss";
import {ReactComponent as GeoLogo} from "svg/logo.svg"; 

const HomePage = () => {
    const navigate = useNavigate();
    const { keycloak } = useKeycloak();

    useEffect(() => {
        if (keycloak?.authenticated) {
            navigate(routes.WORLD);
        }
    }, [keycloak?.authenticated])

    const onLoginClick = useCallback(() => {
        keycloak?.login({scope: "openid email profile"});
    }, [keycloak]);

    return (
        <div className={styles.container}>
            <div className={styles.logo} onClick={onLoginClick}>
                <GeoLogo />
            </div>
        </div>
    )
}

export default memo(HomePage);