import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError, AxiosResponse } from "axios";
import { TAppDispatch, TRootState } from "store";

import { ILoginData, ISignInReturnData } from "./authAdmin.types";
import env from "../../config";

export const signIn = createAsyncThunk<
  AxiosResponse<ISignInReturnData>,
  ILoginData,
  {
    dispatch: TAppDispatch;
    rejectValue: AxiosError;
    state: TRootState;
  }
>(
  "authAdmin/signIn",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const url = `${env.REACT_APP_CITIZEN_API}/auth/sign-in`;
    try {
        return (await axios.post(url, data)) as AxiosResponse<ISignInReturnData>;
    } catch (error) {
        return rejectWithValue(error as AxiosError);
    } 
  },
);