import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { TAppDispatch, TRootState } from "store";
import { IAdminUsersReturnData, ICitizenCreateData, ICitizenUpdateData, ICitizenUser, ICitizenUserMergedWithHub, ICitizenUsersGetParams, ICitizenUsersGetParamsMain } from "./citizen.types";
import { URL_CITIZEN_USER, URL_CITIZEN_USERS, URL_CITIZEN_USERS_WITH_HUB } from "./citizen.api";
import apiCitizen from "helpers/apiCitizen";

export const getCitizenUsers = createAsyncThunk<
  AxiosResponse<IAdminUsersReturnData>,
  ICitizenUsersGetParamsMain,
  {
    dispatch: TAppDispatch;
    rejectValue: AxiosError;
    state: TRootState;
  }
>(
  "citizen/getCitizenUsers",
  async (params, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const config = {
      params,
      headers: {'Cache-Control': 'no-cache'},
    };
    try {
        return (await apiCitizen.get(URL_CITIZEN_USERS, config)) as AxiosResponse<IAdminUsersReturnData>;
    } catch (error) {
        return rejectWithValue(error as AxiosError);
    } 
  },
);

export const getCitizenUsersMerged = createAsyncThunk<
  AxiosResponse<ICitizenUserMergedWithHub[]>,
  ICitizenUsersGetParams,
  {
    dispatch: TAppDispatch;
    rejectValue: AxiosError;
    state: TRootState;
  }
>(
  "citizen/getCitizenUsersMerged",
  async (params, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const config = {
      params,
      headers: {'Cache-Control': 'no-cache'},
    };
    try {
        return (await apiCitizen.get(URL_CITIZEN_USERS_WITH_HUB, config)) as AxiosResponse<ICitizenUserMergedWithHub[]>;
    } catch (error) {
        return rejectWithValue(error as AxiosError);
    } 
  },
);

export const createCitizen = createAsyncThunk<
  AxiosResponse<ICitizenUser>,
  ICitizenCreateData,
  {
    dispatch: TAppDispatch;
    rejectValue: AxiosError;
    state: TRootState;
  }
>(
  "citizen/createCitizen",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
        return (await apiCitizen.post(URL_CITIZEN_USERS, data)) as AxiosResponse<ICitizenUser>;
    } catch (error) {
        return rejectWithValue(error as AxiosError);
    } 
  },
);

export const updateCitizen = createAsyncThunk<
  AxiosResponse<ICitizenUser>,
  { data: ICitizenUpdateData, email: string},
  {
    dispatch: TAppDispatch;
    rejectValue: AxiosError;
    state: TRootState;
  }
>(
  "citizen/updateCitizen",
  async ({data, email}, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const url = URL_CITIZEN_USER(email);
    try {
        return (await apiCitizen.patch(url, data)) as AxiosResponse<ICitizenUser>;
    } catch (error) {
        return rejectWithValue(error as AxiosError);
    } 
  },
);