import { createSlice } from "@reduxjs/toolkit";
import { INITIAL_STATE } from "./workGroup.constants";
import { TRootState } from "store";
import { createWorkGroupCases, getWorkGroupListCases, updateWorkGroupCases } from "./workGroup.reducers";

const workGroupSlice = createSlice({
    initialState: INITIAL_STATE,
    name: "workGroup",
    reducers: {
        setCreatingState(state, action) {
            state.creating = action.payload;
        },
        setUpdatingState(state, action) {
            state.updating = action.payload;
        }
    },
    extraReducers(builder) {
        getWorkGroupListCases(builder);
        createWorkGroupCases(builder);
        updateWorkGroupCases(builder);
    },
});

export const {
    setCreatingState,
    setUpdatingState,
} = workGroupSlice.actions;

export default workGroupSlice.reducer;
export const selectWorkGroupState = (state: TRootState) => state.workGroup;