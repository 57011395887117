import { IUser } from 'entities/user/user.types';
import { UserContext } from 'providers/UserProvider';
import { FC, ReactNode, useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { routes } from './routes';
import { useKeycloak } from '@react-keycloak/web';

interface IRouteProps {
  children: ReactNode;
}

const ProtectedRoute: FC<IRouteProps> = ({ children }) => {
  const user: IUser = useContext(UserContext);

  const { keycloak } = useKeycloak();
  
  if (keycloak?.authenticated && user?.email && !(user?.isAdmin || user?.isDeveloper || user?.isManager)) {
    return <Navigate to={routes.FORBIDDEN} />;
  }

  return children ? <>{children}</> : <Outlet />;
};

export default ProtectedRoute;
