import { useCallback, useState, memo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import { selectCityState } from 'entities/city/city.slice';
import { ICity, ICityCreateData } from 'entities/city/city.types';
import { getRegionList } from 'entities/region/region.thunks';
import { selectCountryState } from 'entities/country/country.slice';
import { selectRegionState } from 'entities/region/region.slice';
import { updateCity } from 'entities/city/city.thunks';
import { ICountry } from 'entities/country/country.types';
import { IRegion } from 'entities/region/region.types';
import { IEditDataModalProps } from 'pages/admin/types';
import { getCountryList } from 'entities/country/country.thunks';

const UpdateCityModal = ({ data: city, onClose, opened }: IEditDataModalProps<ICity>) => {
  const [data, setData] = useState<ICityCreateData>(null);  
  const { updating } = useAppSelector(selectCityState);
  const { items: countries } = useAppSelector(selectCountryState);
  const { items: regions } = useAppSelector(selectRegionState)

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (city?.value) {
      setData({
        name: city?.name,
        title: city?.title,
        value: city?.value,
        timezone: city?.timezone,
        countryId: city?.country?.value,
        regionId: city?.region?.value,
      });
    }
  }, [city]);

  useEffect(() => {
    dispatch(getCountryList({ withEmpty: true }));
  }, []);

  useEffect(() => {
    if (data?.countryId) {
      dispatch(getRegionList({ countryId:data?.countryId, withEmpty: true }));
    }
  }, [data?.countryId]);

  const onConfirmUpdate = () => {
    if (city?.id && data?.value) {
        dispatch(updateCity({ id: city.id, data }));
    }
  }  

  const handleDataChange = useCallback((e) => {
    setData(oldData => ({ ...oldData, [e.target.name]: e.target.value}));
  }, []);

  const handleCountryChange = useCallback((e: SelectChangeEvent) => {
    setData(oldData => ({ ...oldData, countryId: e.target.value}));
  }, []);

  const handleRegionChange = useCallback((e: SelectChangeEvent) => {
    setData(oldData => ({ ...oldData, regionId: e.target.value}));
  }, []);

  return (
      <Dialog
        open={opened}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Обновление города"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              updating === "succeeded" 
              ? 'Данные города успешно обновлены' 
              : 'Заполните обязательные поля'
            }
          </DialogContentText>
          {updating !== "succeeded" && <>
          <TextField
            autoFocus
            margin="normal"
            name="name"
            label="Наименование"
            fullWidth
            variant="outlined"
            value={data?.name}
            onChange={handleDataChange}
          /> 
          <TextField
            margin="normal"
            name="title"
            label="Наименование (EN)"
            fullWidth
            variant="outlined"
            value={data?.title}
            onChange={handleDataChange}
          /> 
          <TextField
            margin="normal"
            name="value"
            label="ID векторного объекта"
            fullWidth
            variant="outlined"
            value={data?.value}
            onChange={handleDataChange}
          /> 
          <TextField
            margin="normal"
            name="timezone"
            label="Часовой пояс"
            fullWidth
            variant="outlined"
            value={data?.timezone}
            onChange={handleDataChange}
          /> 
          <FormControl margin="normal" fullWidth>
            <InputLabel id="select-label-country">Страна</InputLabel>
            <Select
              labelId="select-label-country"
              name="countryId"
              value={data?.countryId ? data?.countryId.toString() : null}
              label="Страна"
              fullWidth
              onChange={handleCountryChange}
              variant='outlined'
            >
              <MenuItem value={null}>-- Не выбрана --</MenuItem>
              {countries?.map((c: ICountry) => <MenuItem value={c.value}>{c.name}</MenuItem>)}
            </Select>
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <InputLabel id="select-label-region">Регион</InputLabel>
            <Select
              labelId="select-label-region"
              disabled={!data?.countryId}
              name="regionId"
              value={data?.regionId ? data?.regionId.toString() : null}
              label="Регион"
              fullWidth
              onChange={handleRegionChange}
              variant='outlined'
            >
              <MenuItem value={null}>-- Не выбран --</MenuItem>
              {regions?.map((c: IRegion) => <MenuItem value={c.value}>{c.title}</MenuItem>)}
            </Select>
          </FormControl></>}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
          {updating === "succeeded" ? 'Закрыть' : 'Отмена'}
          </Button>
          {updating !== "succeeded" &&
            <Button onClick={onConfirmUpdate} autoFocus>
              Сохранить
            </Button>
          }
        </DialogActions>
      </Dialog>
  );
}

export default memo(UpdateCityModal);
