import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IAdminUsersReturnData, ICitizenState, ICitizenUserMergedWithHub } from "./citizen.types";
import { createCitizen, getCitizenUsers, getCitizenUsersMerged, updateCitizen } from "./citizen.thunks";

export const getCitizenUsersCases = (
    builder: ActionReducerMapBuilder<ICitizenState>,
  ) => {
    builder.addCase(getCitizenUsers.pending, state => {
      state.loading = "loading";
      state.error = null;
    });
    builder.addCase(getCitizenUsers.fulfilled, (state, action) => {
      state.loading = "succeeded";
      const data: IAdminUsersReturnData = action.payload?.data;
      state.adminUsers = data;
      state.rows = data?.users?.map(c => ({
          fullName: c.fullName,
          lastName: c.lastName,
          firstName: c.firstName,
          hubUserResource: c.hubUserResource,
          email: c.email,
          cityId: c.cityId,
          citizenId: c.id,
          city: c.city?.name,
          timezone: c.city?.timezone,
          country: c.city?.country?.name,
          grades: c.grades,
          gradesStr: c.grades?.map(g => g?.grade?.name + ' - ' + g?.group?.name)?.join("; "),
          stack: c.stack,
          workGroupId: c.workGroupId,
        }))?.filter(c => c?.email);
    });
    builder.addCase(getCitizenUsers.rejected, (state, action) => {
      state.loading = "failed";
      state.error = action.payload?.message;
    });
  };

  export const getCitizenUsersMergedCases = (
    builder: ActionReducerMapBuilder<ICitizenState>,
  ) => {
    builder.addCase(getCitizenUsersMerged.pending, state => {
      state.loading = "loading";
      state.error = null;
    });
    builder.addCase(getCitizenUsersMerged.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.itemsMerged = action.payload?.data?.map((user: ICitizenUserMergedWithHub) => ({
        ...user,
        lastName: user.lastName || user.person?.lastName,
        firstName: user.firstName || user.person?.firstName,
        initials: user.firstName && user.lastName ? user.firstName?.charAt(0)?.toUpperCase() + user.lastName?.charAt(0)?.toUpperCase() : "",
      }));
    });
    builder.addCase(getCitizenUsersMerged.rejected, (state, action) => {
      state.loading = "failed";
      state.error = action.payload?.message;
    });
  };  

  export const createCitizenCases = (
    builder: ActionReducerMapBuilder<ICitizenState>,
  ) => {
    builder.addCase(createCitizen.pending, state => {
      state.creating = "loading";
      state.error = null;
    });
    builder.addCase(createCitizen.fulfilled, (state, action) => {
      state.creating = "succeeded";
    });
    builder.addCase(createCitizen.rejected, (state, action) => {
      state.creating = "failed";
      state.error = action.payload?.message;
    });
  };

  export const updateCitizenCases = (
    builder: ActionReducerMapBuilder<ICitizenState>,
  ) => {
    builder.addCase(updateCitizen.pending, state => {
      state.updating = "loading";
      state.error = null;
    });
    builder.addCase(updateCitizen.fulfilled, (state, action) => {
      state.updating = "succeeded";
    });
    builder.addCase(updateCitizen.rejected, (state, action) => {
      state.updating = "failed";
      state.error = action.payload?.message;
    });
  };