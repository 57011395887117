import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IDeveloperGroupState } from "./developerGroup.types";
import { createDevGroup, getDevGroupList, updateDevGroup } from "./developerGroup.thunks";

export const getDevGroupListCases = (
    builder: ActionReducerMapBuilder<IDeveloperGroupState>,
  ) => {
    builder.addCase(getDevGroupList.pending, state => {
      state.loading = "loading";
      state.error = null;
    });
    builder.addCase(getDevGroupList.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.items = action.payload.data;
    });
    builder.addCase(getDevGroupList.rejected, (state, action) => {
      state.loading = "failed";
      state.error = action.payload?.message;
    });
  };  

  export const createDevGroupCases = (
    builder: ActionReducerMapBuilder<IDeveloperGroupState>,
  ) => {
    builder.addCase(createDevGroup.pending, state => {
      state.creating = "loading";
      state.error = null;
    });
    builder.addCase(createDevGroup.fulfilled, (state, action) => {
      state.creating = "succeeded";
    });
    builder.addCase(createDevGroup.rejected, (state, action) => {
      state.creating = "failed";
      state.error = action.payload?.message;
    });
  };

  export const updateDevGroupCases = (
    builder: ActionReducerMapBuilder<IDeveloperGroupState>,
  ) => {
    builder.addCase(updateDevGroup.pending, state => {
      state.updating = "loading";
      state.error = null;
    });
    builder.addCase(updateDevGroup.fulfilled, (state, action) => {
      state.updating = "succeeded";
    });
    builder.addCase(updateDevGroup.rejected, (state, action) => {
      state.updating = "failed";
      state.error = action.payload?.message;
    });
  };      