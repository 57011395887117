import { TEntityName, TLoadingState } from "entities/common/common.types";
import { IAdminState } from "./admin.types";

const DELETING_INITIAL_STATE:{[K in TEntityName]: TLoadingState} = {
    "developer-groups": "idle",
    "developer-stack": "idle",
    "work-group": "idle",
    "work-levels": "idle",
    cities: "idle",
    countries: "idle",
    grades: "idle",
    regions: "idle",
    users: "idle",
}

export const INITIAL_STATE: IAdminState = {
    deleting: DELETING_INITIAL_STATE,
    error: null,
}

export const ENTITY_TITLE: {[K in TEntityName]: string} = {
    "developer-groups": "Компетенция",
    "developer-stack": "Стэк",
    "work-group": "Категория",
    "work-levels": "Проф уровень",
    cities: "Город",
    countries: "Страна",
    grades: "Грэйд",
    regions: "Регион",
    users: "Пользователь",
};

export const ENTITY_TITLE_ROD: {[K in TEntityName]: string} = {
    "developer-groups": "компетенции",
    "developer-stack": "стэка",
    "work-group": "категории",
    "work-levels": "проф уровня",
    cities: "города",
    countries: "страны",
    grades: "грэйда",
    regions: "региона",
    users: "пользователя",
};