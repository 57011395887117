import { useCallback, useState, memo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import { IStack, IStackCreateData } from 'entities/stack/stack.types';
import { selectStackState } from 'entities/stack/stack.slice';
import { updateStack } from 'entities/stack/stack.thunks';
import { IEditDataModalProps } from 'pages/admin/types';

const UpdateStackModal = ({ data: stack, onClose, opened }: IEditDataModalProps<IStack>) => {
  const [data, setData] = useState<IStackCreateData>(null);  
  const { updating } = useAppSelector(selectStackState);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (stack?.name) {
      setData({
        name: stack?.name,
      });
    }
  }, [stack]);

  const onConfirmUpdate = () => {
    if (stack?.id && data?.name) {
        dispatch(updateStack({ id: stack.id, data }));
    }
  }  

  const handleDataChange = useCallback((e) => {
    setData(oldData => ({ ...oldData, [e.target.name]: e.target.value}));
  }, []);

  return (
      <Dialog
        open={opened}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Обновление стэка"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              updating === "succeeded" 
              ? 'Данные стэка успешно обновлены' 
              : 'Заполните обязательные поля'
            }
          </DialogContentText>
          {updating !== "succeeded" && <>
            <TextField
              margin="normal"
              name="name"
              label="Наименование"
              fullWidth
              variant="outlined"
              value={data?.name}
              onChange={handleDataChange}
            />
          </>}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
          {updating === "succeeded" ? 'Закрыть' : 'Отмена'}
          </Button>
          {updating !== "succeeded" &&
            <Button onClick={onConfirmUpdate} autoFocus>
              Сохранить
            </Button>
          }
        </DialogActions>
      </Dialog>
  );
}

export default memo(UpdateStackModal);
