import { useCallback, useState, memo, useEffect, ChangeEvent } from 'react';
import { useAppDispatch, useAppSelector } from 'store';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import { updateCitizen } from 'entities/citizen/citizen.thunks';
import { selectCitizenState } from 'entities/citizen/citizen.slice';
import { ICitizenUpdateData, ICitizenUserRow } from 'entities/citizen/citizen.types';
import { selectCityState } from 'entities/city/city.slice';
import { ICity } from 'entities/city/city.types';
import { selectStackState } from 'entities/stack/stack.slice';
import { selectWorkLevelState } from 'entities/workLevel/workLevel.slice';
import { selectWorkGroupState } from 'entities/workGroup/workGroup.slice';
import { IWorkGroup } from 'entities/workGroup/workGroup.types';
import { getWorkLevelList } from 'entities/workLevel/workLevel.thunks';
import { IEditDataModalProps } from 'pages/admin/types';

import styles from "../../AdminUsers.module.scss";
import FormGroupCheckboxes from 'components/FormGroupCheckboxes';

const UpdateUserModal = ({ data: user, onClose, opened }: IEditDataModalProps<ICitizenUserRow>) => {
  const [data, setData] = useState<ICitizenUpdateData>(null);  
  const { updating } = useAppSelector(selectCitizenState);
  const { items: cities } = useAppSelector(selectCityState);
  const { items: stackList } = useAppSelector(selectStackState);
  const { rows: workLevels } = useAppSelector(selectWorkLevelState);
  const { items: workGroups } = useAppSelector(selectWorkGroupState);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user?.email) {
      setData({ 
        cityId: user.cityId, 
        stack: user.stack,
        gradeIDs: user.grades?.map(g => g.id),
        workGroupId: user.workGroupId,
        lastName: user.lastName,
        firstName: user.firstName,
      });
    }
  }, [user]);

  useEffect(() => {
    if (data?.workGroupId) {
      dispatch(getWorkLevelList({ workGroupId: data?.workGroupId }));
    }
  }, [data?.workGroupId]);

  const onConfirmUpdate = () => {
    if (user?.email && data?.cityId) {
        dispatch(updateCitizen({ email: user.email, data }));
    }
  }  

  const handleCityChange = useCallback((e: SelectChangeEvent) => {
    setData(oldData => ({ ...oldData, cityId: e.target.value}));
  }, []);

  const handleWorkGroupChange = useCallback((e: SelectChangeEvent) => {
    setData(oldData => ({ ...oldData, workGroupId: Number(e.target.value)}));
  }, []);

  const handleStackChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event?.target;
    setData(oldData => {
      const copyList = oldData?.stack || [];
      let newList = [];
      if (checked) {
        if (!copyList?.includes(name)) {
          newList = copyList?.concat([name]);
        }
      } else {
        newList = copyList?.filter(st => st !== name);
      }

      return {
        ...oldData,
        stack: newList,
      }
    });
  };

  const handleGradesChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event?.target;
    setData(oldData => {
      const copyList = oldData?.gradeIDs;
      let newList = [];
      if (checked) {
        if (!copyList?.includes(Number(value))) {
          newList = copyList?.concat([Number(value)]);
        }
      } else {
        newList = copyList?.filter(st => st !== Number(value));
      }

      return {
        ...oldData,
        gradeIDs: newList,
      }
    });
  };

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setData(oldData => ({ ...oldData, [name]: value}));
  }, []);

  return (
      <Dialog
        open={opened}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Обновление пользователя"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              updating === "succeeded" 
              ? 'Данные пользователя успешно обновлены' 
              : 'Заполните обязательные поля'
            }
          </DialogContentText>
          {updating !== "succeeded" && <>
            <TextField
              autoFocus
              margin="dense"
              name="lastName"
              label="Фамилия"
              type="text"
              fullWidth
              variant="outlined"
              value={data?.lastName}
              onChange={handleChange}
            /> 
            <TextField
              autoFocus
              margin="dense"
              name="firstName"
              label="Имя"
              type="text"
              fullWidth
              variant="outlined"
              value={data?.firstName}
              onChange={handleChange}
            /> 
            {!!cities?.length &&
              <FormControl fullWidth margin="normal" variant='standard'>
                <span className={styles.selectLabel}>Город</span>
                <Select
                  name="cityId"
                  value={data?.cityId ? data?.cityId.toString() : null}
                  fullWidth
                  onChange={handleCityChange}
                  variant='outlined'
                  displayEmpty
                >
                  <MenuItem value={null}>-- Не выбран --</MenuItem>
                  {cities?.map((city: ICity, index) => <MenuItem key={`city_key_${index}`} value={city.value}>{city.name}</MenuItem>)}
                </Select>
              </FormControl>
            }

            <FormControl fullWidth margin="normal" variant='standard'>
              <span className={styles.selectLabel}>Категория</span>
              <Select
                name="workGroupId"
                value={data?.workGroupId ? data?.workGroupId.toString() : null}
                fullWidth
                onChange={handleWorkGroupChange}
                variant='outlined'
                displayEmpty
              >
                <MenuItem value={null}>-- Не выбрана --</MenuItem>
                {workGroups?.map((wg: IWorkGroup, index) => <MenuItem key={`work_group_key_${index}`} value={wg.id}>{wg.name}</MenuItem>)}
              </Select>
            </FormControl>

            <FormGroupCheckboxes
              label='Стэк:'
              items={stackList}
              selectedValues={data?.stack || []}
              handleChange={handleStackChange}
              valueField="name"
            />

            {!!data?.workGroupId && workLevels?.length > 0 &&
              <FormGroupCheckboxes
                label='Грэйды:'
                items={workLevels}
                selectedValues={data?.gradeIDs || []}
                handleChange={handleGradesChange}
                valueField="id"
              />
            }
          </>}                      
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>
          {updating === "succeeded" ? 'Закрыть' : 'Отмена'}
          </Button>
          {updating !== "succeeded" &&
            <Button onClick={onConfirmUpdate} autoFocus>
              Сохранить
            </Button>
          }
        </DialogActions>
      </Dialog>
  );
}

export default memo(UpdateUserModal);
