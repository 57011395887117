import cn from "classnames";
import { IUserCardProps } from "./types";
import styles from "./UserCard.module.scss";
import { peopleColors } from "entities/user/user.constants";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import UserAvatar from "components/UserAvatar";
import Chip from "@mui/material/Chip";

import StarsGif from 'images/giphy-14.gif';
import GradeImage from "components/GradeImage";
import { IWorkLevel } from "entities/workLevel/workLevel.types";

const UserCard = ({user}: IUserCardProps) => {
    const color = peopleColors[user?.workGroup?.name] || "blue";
    const fileName = `${user?.email?.split('@')?.[0]}.jpg`;
    const avatarAlt = `${user?.person?.firstName} ${user?.person?.lastName}`;
    
    return (
        <Box className={styles.box}>
            <div className={cn(styles.card, styles[`card--${color}`])}>
                <img alt="" className={styles.stars} src={StarsGif}/>
                <div className={styles.workPosition}>{user?.workPosition?.jobTitle}</div>
                <div className={styles.person}>
                    <UserAvatar className={styles.avatar} alt={avatarAlt} fileName={fileName} category={user?.workGroup?.name}/>
                    {(user?.person || (user?.lastName && user?.firstName)) &&
                        <div className={styles.name}>
                            {user.person?.firstName || user.firstName}<br/>{user.person?.lastName || user.lastName}
                        </div>
                    }
                    {user?.city?.name &&
                        <div className={styles.city}>{`город ${user?.city?.name} ${user?.city?.timezone}`}</div>
                    }
                </div>

                {/*recomendation[user?.email] && (
                    <div className={styles.recomendation}>
                        <div className={styles.arrow}/>
                        <span>...рекомендую посетить в моем городе</span>
                    </div>
                )*/}

                {!!user?.grades?.length && 
                    <>
                        <Divider className={styles.devider}>GRADE LEVELS</Divider>
                        <div className={styles.achives}>
                            {user.grades.map((level: IWorkLevel) => (
                                <GradeImage 
                                    className={styles.active} 
                                    alt={`${level?.group?.name}:${level?.grade?.name}`}
                                    fileName={`${level?.group?.name?.replace('/','')?.toLowerCase()}-${level.grade?.name?.toLowerCase()}.svg`}
                                />
                            ))}
                        </div>
                    </>
                }

                {!!user?.stack?.length &&
                    <>
                        <Divider className={styles.devider}>STACK</Divider>
                        <div className={styles.stackItems}>
                            {user.stack.map((s) => (
                                <Chip sx={{ marginRight: "4px", marginBottom: "4px" }} label={s} color="primary"/>
                            ))}
                        </div>
                        
                    </>
                }
            </div>
        </Box>
    )
}

export default UserCard;