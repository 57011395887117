import { IRegionState } from "./region.types";

export const INITIAL_STATE: IRegionState = {
    items: [],
    itemsFiltered: [],
    rows: [],
    loading: "idle",
    creating: "idle",
    updating: "idle",
    error: null,
}