import { IColumn } from "../types";
import { IDialogUserData } from "./types";

export const columns: IColumn[] = [
    { 
      id: 'id', 
      label: 'ID', 
      width: 90 
    },
    {
      id: 'fullName',
      label: "Имя Фамилия",
      width: 200,
    },
    {
      id: 'email',
      label: 'Email',
      width: 200,
    },
    {
      id: 'city',
      label: 'Город',
      width: 200,
    },
    {
      id: 'country',
      label: 'Страна',
      width: 150,
    },
    {
      id: 'stack',
      label: 'Стэк',
      width: 300,
    },
    {
      id: 'gradesStr',
      label: 'Грэйды',
      width: 300,
    },
    {
      id: 'hubUserResource',
      label: "HUB Resource",
      width: 300,
    },
    {
      id: 'actions',
      label: "",
    }
  ];

export const defaultDialogData: IDialogUserData = {
  open: false,
  user: null,
}  