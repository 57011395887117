import { createSlice } from "@reduxjs/toolkit";
import { INITIAL_STATE } from "./city.constants";
import { createCityCases, getCityListCases, updateCityCases } from "./city.reducers";
import { TRootState } from "store";

const citySlice = createSlice({
    initialState: INITIAL_STATE,
    name: "city",
    reducers: {
        setCreatingState(state, action) {
            state.creating = action.payload;
        },
        setUpdatingState(state, action) {
            state.updating = action.payload;
        }
    },
    extraReducers(builder) {
        getCityListCases(builder);
        createCityCases(builder);
        updateCityCases(builder);
    },
});

export const {
    setCreatingState,
    setUpdatingState,
} = citySlice.actions;

export default citySlice.reducer;
export const selectCityState = (state: TRootState) => state.city;