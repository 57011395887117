import { FC, ReactNode, createContext, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useAppDispatch, useAppSelector } from 'store';
import { getMeThunk, profileSelector } from 'entities/profile/profile.slice';
import { IUser } from '@entities/user/user.types';

interface IUserProvider {
  children: ReactNode;
}

export const UserContext = createContext<IUser | null>(null);

export const UserProvider: FC<IUserProvider> = ({ children }) => {
  const { keycloak } = useKeycloak();

  const {me} = useAppSelector(profileSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (keycloak?.authenticated && keycloak?.tokenParsed?.email) {
      const slug = keycloak.tokenParsed.email.replace('@','-').replace('.','-');
      dispatch(getMeThunk(slug));
    }
  }, [keycloak?.authenticated]);

  return (
    <UserContext.Provider value={me}>
      {children}
    </UserContext.Provider>
  );
};
