import { IColumn } from "../types";
import { IDialogRegionData } from "./types";

export const columns: IColumn[] = [
    { 
      id: 'id', 
      label: 'ID', 
      width: 90 
    },
    {
      id: 'title',
      label: 'Наименование',
      width: 250,
    },
    {
      id: 'value',
      label: 'ID Path',
      width: 150,
    },
    {
      id: 'country',
      label: 'Страна',
      width: 150,
    },
    {
      id: 'userCount',
      label: 'Users',
      width: 100,
    },
    {
      id: 'actions',
      label: "",
    }
  ];

export const defaultDialogData: IDialogRegionData = {
  open: false,
  region: null,
}  