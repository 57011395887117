import {memo, SyntheticEvent, useState} from "react";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import AdminUsers from "./users/AdminUsers";
import AdminCities from "./cities/AdminCities";
import AdminRegions from "./regions/AdminRegions";
import AdminCountries from "./countries/AdminCountries";
import AdminStacks from "./stacks/AdminStacks";
import AdminWorkLevels from "./workLevels/AdminWorkLevels";
import AdminWorkGroups from "./workGroups/AdminWorkGroups";
import AdminDevGroups from "./devGroups/AdminDevGroups";

import AdminAuthPage from "./auth/AdminAuth";

import { useAppSelector } from "store";
import { selectAuthAdminState } from "entities/authAdmin/authAdmin.slice";
import { ADMIN_TABS } from "./constants";
import { TEntityName } from "@entities/common/common.types";

const AdminPage = () => {
    const [value, setValue] = useState<TEntityName>('users');
    const { token } = useAppSelector(selectAuthAdminState);

    const handleChange = (event: SyntheticEvent, newValue: TEntityName) => {
      setValue(newValue);
    };

    if (!token) return (
      <AdminAuthPage />
    );

    const tabStyle = {width: "100%"};

    return (
        <Box sx={{ width: '100%', typography: 'body1',  display: 'flex' }}>
        <TabContext value={value}>
          <Box sx={{ borderRight: 1, borderColor: 'divider', paddingTop: "32px" }}>
            <TabList sx={{width: "200px"}} centered variant="scrollable" orientation="vertical" onChange={handleChange}>
              {ADMIN_TABS.map((tab) => <Tab {...tab} />)}
            </TabList>
          </Box>

          <TabPanel sx={tabStyle} value="users"><AdminUsers /></TabPanel>
          <TabPanel sx={tabStyle} value="cities"><AdminCities /></TabPanel>
          <TabPanel sx={tabStyle} value="regions"><AdminRegions /></TabPanel>
          <TabPanel sx={tabStyle} value="countries"><AdminCountries /></TabPanel>
          <TabPanel sx={tabStyle} value="developer-stack"><AdminStacks /></TabPanel>
          <TabPanel sx={tabStyle} value="work-levels"><AdminWorkLevels /></TabPanel>
          <TabPanel sx={tabStyle} value="work-group"><AdminWorkGroups /></TabPanel>
          <TabPanel sx={tabStyle} value="developer-groups"><AdminDevGroups /></TabPanel>
        </TabContext>
      </Box>
    )
}

export default memo(AdminPage);