import { useEffect, useState, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store';

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Alert from '@mui/material/Alert';

import { getCitizenUsers } from 'entities/citizen/citizen.thunks';
import { selectCitizenState, setCreatingState, setUpdatingState } from 'entities/citizen/citizen.slice';
import { getCityList } from 'entities/city/city.thunks';
import { getStackList } from 'entities/stack/stack.thunks';
import { getWorkGroupList } from 'entities/workGroup/workGroup.thunks';
import { columns, defaultDialogData } from './constants';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@mui/material';

import styles from "./AdminUsers.module.scss";
import { ICitizenUserRow } from 'entities/citizen/citizen.types';
import RemoveItemModal from 'pages/admin/components/RemoveItemModal';
import CreateUserModal from './components/CreateUserModal';
import UpdateUserModal from './components/UpdateUserModal';
import { IUser } from 'entities/user/user.types';
import { IDialogUserData } from './types';
import { selectAdminState, setDeletingState } from 'entities/admin/admin.slice';
import useTablePagination from 'hooks/useTablePagination';

const AdminUsers = () => {
  const {page, rowsPerPage, handleChangePage, handleChangeRowsPerPage} = useTablePagination();

  const [removeDialog, setRemoveDialog] = useState<IDialogUserData>(defaultDialogData);
  const [updateDialog, setUpdateDialog] = useState<IDialogUserData>(defaultDialogData);
  const [createDialog, setCreateDialog] = useState<boolean>(false);

  const {adminUsers, rows, creating, updating} = useAppSelector(selectCitizenState);
  const {deleting} = useAppSelector(selectAdminState);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCityList());
    dispatch(getWorkGroupList());
    dispatch(getStackList());
    dispatch(getCitizenUsers({ }));
  }, []);

  useEffect(() => {
    if (deleting.users === "succeeded" || creating === "succeeded" || updating === "succeeded") {
      dispatch(getCitizenUsers({ }));
    }
  }, [deleting.users, creating, updating]);

  const handleAddUserClick = () => {
    dispatch(setCreatingState('idle'));
    setCreateDialog(true);
  }

  const handleCreateDialogClose = useCallback(() => {
    setCreateDialog(false);
  }, []);

  const handleRemoveUserClick = (user: ICitizenUserRow) => {
    dispatch(setDeletingState({entity: 'users', value: 'idle'}));
    setRemoveDialog({ user, open: true });
  }

  const handleRemoveDialogClose = useCallback(() => {
    setRemoveDialog(defaultDialogData);
  }, []);

  const handleEditUserClick = (user: ICitizenUserRow) => {
    dispatch(setUpdatingState('idle'));
    setUpdateDialog({ user, open: true });
  }

  const handleUpdateDialogClose = useCallback(() => {
    setUpdateDialog(defaultDialogData);
  }, []);

  return (
    <>
  <Paper sx={{ width: '100%', height: '100%', overflow: 'hidden' }}>
    {adminUsers?.newUsers?.length > 0 &&
      <Alert severity="warning">
        {`В хабе обнаружено ${adminUsers?.newUsers?.length} новых пользователей. Добавьте их на карту.`}
        <br/>
        <ul>
        {adminUsers?.newUsers?.map((u:IUser) => (
          <li>{`${u?.person?.firstName} ${u?.person?.lastName} (${u?.email})`}</li>
        ))}
        </ul>
      </Alert>
    }
    <div>
      <Button onClick={handleAddUserClick} size='large' startIcon={<AddIcon />}>
        Добавить пользователя
      </Button>
    </div>

    <TableContainer sx={{ maxHeight: 720 }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ width: column.width }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.id === "actions" ? 
                          (<div className={styles.actionsCell}>
                            <EditIcon onClick={() => handleEditUserClick(row)} />
                            <DeleteIcon onClick={() => handleRemoveUserClick(row)}/>
                          </div>) 
                          : column.id === "stack" ? value?.join("; ") : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[10, 25, 100]}
      component="div"
      count={rows.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  </Paper>

    {removeDialog.open && removeDialog.user &&
        <RemoveItemModal
          opened={removeDialog.open}
          onClose={handleRemoveDialogClose}
          id={removeDialog.user.email}
          name={removeDialog.user.email}
          entity='users'
        />  
    }

    {createDialog &&
        <CreateUserModal
          opened={createDialog}
          onClose={handleCreateDialogClose}
        />  
    }

    {updateDialog.open && updateDialog.user &&
        <UpdateUserModal
          opened={updateDialog.open}
          onClose={handleUpdateDialogClose}
          data={updateDialog.user}
        />  
    }
  </>
  );
}

export default AdminUsers;
