import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import { IUserListProps } from './types';
import { ICountry } from 'entities/country/country.types';
import { IRegion } from 'entities/region/region.types';

import styles from "./UserList.module.scss";
import { useAppDispatch, useAppSelector } from 'store';
import UserAvatar from 'components/UserAvatar';
import { selectCountryState } from 'entities/country/country.slice';
import { selectCitizenState } from 'entities/citizen/citizen.slice';
import { getCitizenUsersMerged } from 'entities/citizen/citizen.thunks';
import { selectRegionState } from 'entities/region/region.slice';
import { ICitizenUserMergedWithHub } from 'entities/citizen/citizen.types';

const UserList = ({ countryId, regionId, onItemClick }: IUserListProps) => {
    const [country, setCountry] = useState<ICountry>(null);
    const [region, setRegion] = useState<IRegion>(null);

    const { items: countries_array } = useAppSelector(selectCountryState);
    const { items: regions_array } = useAppSelector(selectRegionState);
    const { itemsMerged, loading } = useAppSelector(selectCitizenState);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (countryId && typeof countryId === "string") {
            dispatch(getCitizenUsersMerged({ countryId }));
            setCountry(countries_array.find(c => c.value === countryId));
        } else if (regionId && typeof regionId === "string") {
            dispatch(getCitizenUsersMerged({ regionId }))
            setRegion(regions_array.find(c => c.value === regionId));
        }
    }, [countryId, regionId, countries_array, regions_array]);

    return (
        <List className={styles.list}
              subheader={<Typography className={styles.title} variant="h5" component="h5" align="center">
                            {country?.name || region?.title || ""}
                        </Typography>}>
            {loading === "loading" &&
                <Box sx={{ display: 'flex', justifyContent: "center", marginTop: 16 }}>
                    <CircularProgress />
                </Box>
            }

            {loading === "succeeded" && itemsMerged?.map((user: ICitizenUserMergedWithHub, ind) => {
                const fileName = `${user?.email?.split('@')?.[0]}.jpg`;
                const avatarAlt = `${user?.person?.firstName} ${user?.person?.lastName}`;
                return (
                <div key={ind}>
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar onClick={() => onItemClick(user)}>
                            <UserAvatar alt={avatarAlt} fileName={fileName} category={user?.workGroup?.name}/>
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <div className={styles.name} onClick={() => onItemClick(user)}>
                                        {user?.firstName} {user?.lastName}
                                    </div>
                                </React.Fragment>
                            }
                            secondary={
                                <React.Fragment>
                                <Typography
                                    component="div"
                                    variant="body2"
                                    color="CaptionText"
                                >
                                    {user?.workPosition?.jobTitle || ''}
                                </Typography>
                                <Typography
                                    component="a"
                                    color="mediumblue"
                                >
                                    {user?.email}
                                </Typography>
                                <Typography
                                    component="div"
                                    variant="body2"
                                    color="GrayText"
                                >
                                    {user?.city?.name || ''} {user?.city?.timezone || ''}
                                </Typography>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                </div>
            )})}
        </List>
    )
}

export default UserList;