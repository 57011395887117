import { useState, useCallback, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";

import Drawer from "@mui/material/Drawer";
import Modal from '@mui/material/Modal';
import MapWorld from "components/MapWorld";
import UserList from "components/UserList";
import ProjectList from "components/ProjectList";
import MapContainer from "containers/MapContainer";
import UserCard from "components/UserCard";
import MapLoader from "components/MapLoader";

import { useAppDispatch, useAppSelector } from "store";

import { mapSelector, setMapView } from "entities/map/map.slice";
import { IProject } from "entities/project/project.types";
import { getCountryList } from "entities/country/country.thunks";
import { getGradeList } from "entities/grade/grade.thunks";
import { ICitizenUserMergedWithHub } from "entities/citizen/citizen.types";
import { filterCountriesByUserCategory, resetCountriesFilters, selectCountryState } from "entities/country/country.slice";
import { getCitizenUsersMerged } from "entities/citizen/citizen.thunks";

const WorldPage = () => {
    const { keycloak } = useKeycloak();
    const [drawerOpened, setDrawerOpened] = useState(false);
    const [currentCountryId, setCurrentCountryId] = useState(null);

    const [currentUser, setCurrentUser] = useState<ICitizenUserMergedWithHub>(null);
    const [currentProject, setCurrentProject] = useState(null);

    const { loading: loadingCountries } = useAppSelector(selectCountryState);

    const {view} = useAppSelector(mapSelector);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (keycloak?.authenticated) {
            dispatch(getCountryList({ }));
            dispatch(getCitizenUsersMerged({ }));
            dispatch(getGradeList());
            dispatch(setMapView("count"));
        }
    }, [keycloak?.authenticated]);
  
    const toggleDrawer = useCallback((country_id) => {
      setCurrentUser(null);
      setCurrentProject(null);
      setCurrentCountryId(drawerOpened ? null : country_id);
      setDrawerOpened((oldValue) => !oldValue)
    }, []);

    const onSelectUser = useCallback((user: ICitizenUserMergedWithHub) => {
        setCurrentUser(user);
        setCurrentProject(null);
    }, [currentUser, currentProject]);

    const onSelectProject = useCallback((p: IProject) => {
        setCurrentUser(null);
        setCurrentProject(p);
    }, [currentUser, currentProject]);

    const onFilterUsersByGroup = useCallback((id: number | null) => {
        if (id) {
            dispatch(filterCountriesByUserCategory(id));
        } else {
            dispatch(resetCountriesFilters());
        }
        dispatch(setMapView("count"));
    }, []);

    const onFilterUsersByProject = useCallback((sourceId: string | null) => {
        if (sourceId) {
            const project = sourceId ? { "userParams.project": sourceId } : null;
            dispatch(getCitizenUsersMerged({...project}));
            dispatch(setMapView("people"));
        } else {
            dispatch(setMapView("count"));
        }
    }, []);
    
    return (
        <>
        {(loadingCountries === "loading") && <MapLoader />}
        <MapContainer 
            title="WORLD"
            onFilterUsersByGroup={onFilterUsersByGroup}
            onFilterUsersByProject={onFilterUsersByProject}
        >    
            <MapWorld onPinClick={toggleDrawer} onPinUserClick={setCurrentUser}/>
    
            <Drawer
            anchor="right"
            open={drawerOpened}
            onClose={toggleDrawer}
            >
                {view === "count" &&
                    <UserList countryId={currentCountryId} onItemClick={onSelectUser}/>
                }
                {view === "projects" &&
                    <ProjectList countryId={currentCountryId} onItemClick={onSelectProject} />
                }
            </Drawer>

            {currentUser?.email &&
                <Modal open={true} onClose={() => setCurrentUser(null)}>
                    <UserCard user={currentUser}/>
                </Modal>
            }
        </MapContainer>
        </>
    )
}

export default WorldPage;
