import { memo } from "react";
import useImage from 'hooks/useImage'
import { Avatar } from "@mui/material";
import { IUserAvatarProps } from "./types";
import UserIcon from "components/UserIcon";

const UserAvatar = ({ className, fileName, alt, category }: IUserAvatarProps) => {
    const { error, image } = useImage(fileName);
    return (image && !error) 
    ? <Avatar className={className} src={image} alt={alt} /> 
    : <Avatar className={className} alt={alt}><UserIcon category={category}/></Avatar>;
}

export default memo(UserAvatar);