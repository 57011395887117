import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./authAdmin.constants";
import { TLoadingState } from "entities/common/common.types";
import { TRootState } from "store";
import { signInCases } from "./authAdmin.reducers";
import { removeAdminTokenFromLocalStorage } from "helpers/tokens";

const authAdminSlice = createSlice({
    name: "authAdmin",
    initialState: initialState,
    extraReducers: (builder) => {
        signInCases(builder);
    },
    reducers: {
        setLoadingState(state, action: PayloadAction<TLoadingState>) {
            state.loading = action.payload;
        },
        logout(state) {
            removeAdminTokenFromLocalStorage();
            state.loading = 'idle';
            state.authorized = false;
            state.token = null;
        },
        removeError(state) {
            state.error = null;
        },
    },
});

export const {
    logout,
    setLoadingState,
    removeError,
} = authAdminSlice.actions;

export default authAdminSlice.reducer;
export const selectAuthAdminState = (state: TRootState) => state.authAdmin;
