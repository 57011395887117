import { createSlice } from "@reduxjs/toolkit";
import { INITIAL_STATE } from "./developerGroup.constants";
import { createDevGroupCases, getDevGroupListCases, updateDevGroupCases } from "./developerGroup.reducers";
import { TRootState } from "store";

const devGroupSlice = createSlice({
    initialState: INITIAL_STATE,
    name: "devGroup",
    reducers: {
        setCreatingState(state, action) {
            state.creating = action.payload;
        },
        setUpdatingState(state, action) {
            state.updating = action.payload;
        }
    },
    extraReducers(builder) {
        getDevGroupListCases(builder);
        createDevGroupCases(builder);
        updateDevGroupCases(builder);
    },
});

export const {
    setCreatingState,
    setUpdatingState,
} = devGroupSlice.actions;

export default devGroupSlice.reducer;
export const selectDevGroupState = (state: TRootState) => state.devGroup;