import { createSlice } from "@reduxjs/toolkit";
import { INITIAL_STATE } from "./workLevel.constants";
import { createWorkLevelCases, getWorkLevelListCases, updateWorkLevelCases } from "./workLevel.reducers";
import { TRootState } from "store";

const workLevelSlice = createSlice({
    initialState: INITIAL_STATE,
    name: "workLevel",
    reducers: {
        setCreatingState(state, action) {
            state.creating = action.payload;
        },
        setUpdatingState(state, action) {
            state.updating = action.payload;
        }
    },
    extraReducers(builder) {
        getWorkLevelListCases(builder);
        createWorkLevelCases(builder);
        updateWorkLevelCases(builder);
    },
});

export const {
    setCreatingState,
    setUpdatingState,
} = workLevelSlice.actions;

export default workLevelSlice.reducer;
export const selectWorkLevelState = (state: TRootState) => state.workLevel;