import profile from "entities/profile/profile.slice";
import map from "entities/map/map.slice";
import projects from "entities/project/project.slice";
import auth from "entities/auth/auth.slice";
import authAdmin from "entities/authAdmin/authAdmin.slice";
import grade from "entities/grade/grade.slice";
import stack from "entities/stack/stack.slice";
import devGroup from "entities/developerGroup/developerGroup.slice";
import city from "entities/city/city.slice";
import country from "entities/country/country.slice";
import region from "entities/region/region.slice";
import citizen from "entities/citizen/citizen.slice";
import workLevel from "entities/workLevel/workLevel.slice";
import workGroup from "entities/workGroup/workGroup.slice";
import admin from "entities/admin/admin.slice";

const rootReducer = {
    auth,
    authAdmin,
    devGroup,
    grade,
    profile,
    projects,
    stack,
    city,
    country,
    map,
    region,
    citizen,
    workLevel,
    workGroup,
    admin,
};

export default rootReducer;