import { useCallback, useState, memo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import { selectCountryState } from 'entities/country/country.slice';
import { ICountry } from 'entities/country/country.types';
import { createRegion } from 'entities/region/region.thunks';
import { selectRegionState } from 'entities/region/region.slice';
import { IRegion, IRegionCreateData } from 'entities/region/region.types';
import { IEditDataModalProps } from 'pages/admin/types';
import { getCountryList } from 'entities/country/country.thunks';

const CreateRegionModal = ({ onClose, opened }: IEditDataModalProps<IRegion>) => {
  const defaultData: IRegionCreateData = { 
    title: null,
    value: null,
    countryId: null,
  };
  const [data, setData] = useState<IRegionCreateData>(defaultData);  

  const { creating } = useAppSelector(selectRegionState);
  const { items: countries } = useAppSelector(selectCountryState);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCountryList({ withEmpty: true }));
  }, []);

  const onConfirmCreate = () => {
    if (data?.title && data?.value) {
        dispatch(createRegion(data));
    }
  }  

  const handleDataChange = useCallback((e) => {
    setData(oldData => ({ ...oldData, [e.target.name]: e.target.value}));
  }, []);

  const handleCountryChange = useCallback((e: SelectChangeEvent) => {
    setData(oldData => ({ ...oldData, countryId: e.target.value}));
  }, []);

  return (
      <Dialog
        open={opened}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Добавление региона"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              creating === "succeeded" 
              ? 'Регион успешно добавлен' 
              : 'Заполните обязательные поля'
            }
          </DialogContentText>
          {creating !== "succeeded" && <>
          <TextField
            margin="normal"
            name="title"
            label="Наименование"
            fullWidth
            variant="outlined"
            value={data?.title}
            onChange={handleDataChange}
          /> 
          <TextField
            margin="normal"
            name="value"
            label="ID векторного объекта"
            fullWidth
            variant="outlined"
            value={data?.value}
            onChange={handleDataChange}
          /> 
          <FormControl margin="normal" fullWidth>
            <InputLabel id="select-label-country">Страна</InputLabel>
            <Select
              labelId="select-label-country"
              name="countryId"
              value={data?.countryId ? data?.countryId.toString() : null}
              label="Страна"
              fullWidth
              onChange={handleCountryChange}
              variant='outlined'
            >
              <MenuItem value={null}>-- Не выбрана --</MenuItem>
              {countries?.map((c: ICountry) => <MenuItem value={c.value}>{c.name}</MenuItem>)}
            </Select>
          </FormControl>
          </>}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
          {creating === "succeeded" ? 'Закрыть' : 'Отмена'}
          </Button>
          {creating !== "succeeded" &&
            <Button onClick={onConfirmCreate} autoFocus>
              Сохранить
            </Button>
          }
        </DialogActions>
      </Dialog>
  );
}

export default memo(CreateRegionModal);
