import {memo} from "react";
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import PencilGif from 'images/animate-pencil.gif';
import styles from "./MapLoader.module.scss";

const MapLoader = () => {
    return (
     <Backdrop
        sx={{ 
          backgroundColor: "rgba(255, 255, 255, .8)", 
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: '#303030', 
          zIndex: (theme) => theme.zIndex.drawer + 1 
        }}
        open={true}
      > 
        <img className={styles.loaderImage} alt="Рисую карту..." src={PencilGif} />
        <Typography variant="h5">Рисую карту...</Typography>
        <Typography variant="h6">{`иногда долго, зато красиво 😺`}</Typography>
     </Backdrop>
    )
}

export default memo(MapLoader);
