import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IWorkGroupState } from "./workGroup.types";
import { createWorkGroup, getWorkGroupList, updateWorkGroup } from "./workGroup.thunks";

export const getWorkGroupListCases = (
    builder: ActionReducerMapBuilder<IWorkGroupState>,
  ) => {
    builder.addCase(getWorkGroupList.pending, state => {
      state.loading = "loading";
      state.error = null;
    });
    builder.addCase(getWorkGroupList.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.items = action.payload.data;
    });
    builder.addCase(getWorkGroupList.rejected, (state, action) => {
      state.loading = "failed";
      state.error = action.payload?.message;
    });
  }; 

  export const createWorkGroupCases = (
    builder: ActionReducerMapBuilder<IWorkGroupState>,
  ) => {
    builder.addCase(createWorkGroup.pending, state => {
      state.creating = "loading";
      state.error = null;
    });
    builder.addCase(createWorkGroup.fulfilled, (state, action) => {
      state.creating = "succeeded";
    });
    builder.addCase(createWorkGroup.rejected, (state, action) => {
      state.creating = "failed";
      state.error = action.payload?.message;
    });
  };

  export const updateWorkGroupCases = (
    builder: ActionReducerMapBuilder<IWorkGroupState>,
  ) => {
    builder.addCase(updateWorkGroup.pending, state => {
      state.updating = "loading";
      state.error = null;
    });
    builder.addCase(updateWorkGroup.fulfilled, (state, action) => {
      state.updating = "succeeded";
    });
    builder.addCase(updateWorkGroup.rejected, (state, action) => {
      state.updating = "failed";
      state.error = action.payload?.message;
    });
  };    