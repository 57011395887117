import { useCallback, useState, MouseEvent } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router";

import Typography from "@mui/material/Typography";
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import Button from "@mui/material/Button";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { routes } from "router/routes";

import { IHeaderProps } from "./types";
import styles from "./Header.module.scss";
import {ReactComponent as GeoLogo} from "svg/logo.svg"; 

const Header = ({ authenticated, person, title}: IHeaderProps) => {
    const [anchorMenuEl, setAnchorMenuEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorMenuEl);
    const navigate = useNavigate();
    const { keycloak } = useKeycloak();

    const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorMenuEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorMenuEl(null);
    };

    const onLoginClick = useCallback(() => {
        keycloak?.login({scope: "openid email profile"});
    }, [keycloak]);

    const onLogoutClick = useCallback(() => {
        keycloak?.logout({redirectUri: window.location.origin});
        setAnchorMenuEl(null);
    }, [keycloak]);

    const onLogoClick = useCallback(() => {
        navigate(routes.WORLD);
    }, []);

    return (
        <div className={styles.container}>
            <GeoLogo className={styles.logo} onClick={onLogoClick}/>
            {title &&
            <Typography variant="h4" component="h4" align="center">
                {title}
            </Typography>
            }
            <div className={styles.profile}>
                {authenticated && person ? <>
                    <div>
                        <Button
                            id="profile-menu-button"
                            aria-controls={open ? 'profile-menu-button' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleMenuClick}
                            sx={{ color: '#6b6b6b', whiteSpace: "nowrap" }}
                        >
                            {`${person?.firstName || "Agent"} ${person?.lastName || "007"}`}
                            <PermIdentityIcon fontSize="small"/>
                        </Button>
                        <Menu
                            id="profile-menu-button"
                            anchorEl={anchorMenuEl}
                            open={open}
                            onClose={handleMenuClose}
                            MenuListProps={{
                                'aria-labelledby': 'profile-menu-button',
                            }}
                        >
                            <MenuItem onClick={onLogoutClick}>Выйти</MenuItem>
                        </Menu>
                    </div>                    
                </> : <Button size="small" onClick={onLoginClick}>Войти</Button>}       

            </div>
        </div>
    )
}

export default Header;