import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useKeycloak } from '@react-keycloak/web';
import QRCode from "react-qr-code";

import {IMapContainerProps} from "./types";
import styles from "./MapContainer.module.scss";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ToolsPanel from "components/ToolsPanel";
import ZoomSlider from "components/ZoomSlider";
import Header from "components/Header";
import Draggable from "components/Draggable";

import { useAppDispatch, useAppSelector } from "store";
import { TMapViewType } from "entities/map/map.types";
import { setMapView } from "entities/map/map.slice";
import { profileSelector } from "entities/profile/profile.slice";
import { getWorkGroupList } from "entities/workGroup/workGroup.thunks";
import { selectWorkGroupState } from "entities/workGroup/workGroup.slice";
import { ITEM_ALL } from "./constants";

const MapContainer = ({
  children, 
  title, 
  onFilterUsersByGroup,
  onFilterUsersByProject,
}: IMapContainerProps) => {
  const [toolsOpen, setToolsOpen] = useState(true);
  const { me } = useAppSelector(profileSelector);
  const mapContainerRef = useRef<HTMLDivElement>();
  const dispatch = useAppDispatch();
  const { keycloak } = useKeycloak()

  const myProjects = useMemo(() => {
    if (me?.isDeveloper) return [{ id: null, title: ITEM_ALL }, ...me?.projectsAsDeveloper];
    else if (me?.isManager) return [{ id: null, title: ITEM_ALL }, ...me?.projectsAsManager];
    else return [];
  }, [me]);

  const {items: workGroups} = useAppSelector(selectWorkGroupState);
  const workGroupItems = useMemo(() => [{ id: null, name: ITEM_ALL }, ...workGroups], [workGroups]);

  useEffect(() => {
      dispatch(getWorkGroupList());
  }, []);

  const handleSetViewType = useCallback((value: TMapViewType) => {
    dispatch(setMapView(value));
  }, []);
  
  const handleSetZoom = useCallback((zoom: number) => {
    if (mapContainerRef?.current) {

      const childs = Array.from(mapContainerRef.current.children);
      childs[0]?.setAttribute("style", `transform: scale(${zoom/100})`);
    }
  }, []);

  const handleFilterByTeam = useCallback((value: number | null) => {
    onFilterUsersByGroup(value);
  }, []);

  const handleFilterByProject = useCallback((value: string | null) => {
    onFilterUsersByProject(value);
  }, []);

  const handleToolsPanelClose = useCallback(() => {
    setToolsOpen(false);
  }, [toolsOpen]);

  const handleToolsPanelOpen = useCallback(() => {
    setToolsOpen(true);
  }, [toolsOpen]);

  return (
    <>
      <Header authenticated={keycloak?.authenticated} person={me?.person} title={title}/>
      {keycloak?.authenticated && me?.email && <>
        <div className={styles.toolsWrapper}>
          {!toolsOpen &&
            <button className={styles.toolsArrowButton} onClick={handleToolsPanelOpen}>
              <ArrowForwardIosIcon fontSize="small"/>
            </button>
          }

          {(workGroupItems?.length > 0 || myProjects?.length > 0) &&
            <ToolsPanel
              open={toolsOpen}
              className={styles.tools}
              categories={workGroupItems}
              projects={myProjects}
              onFilterByTeam={handleFilterByTeam} 
              onFilterByProject={handleFilterByProject}
              onViewChange={handleSetViewType}
              onClosePanel={handleToolsPanelClose}
            />
          }
          <ZoomSlider onZoomChanged={handleSetZoom} />
        </div>

        <div className={styles.qrcode}>
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value="https://geo.hub.crtweb.ru"
            viewBox={`0 0 256 256`}
          />
        </div>
      </>}
      <div className={styles.container} ref={mapContainerRef} >
          <Draggable>
            {children}
          </Draggable>
      </div>
    </>
  )
}

export default MapContainer;