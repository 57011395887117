import { IAdminTab } from "./types";

export const ADMIN_TABS: IAdminTab[] = [
    {
        label: "Пользователи",
        value: "users"
    },
    {
        label: "Категории пользователей",
        value: "work-group"
    },
    {
        label: "Компетенции",
        value: "developer-groups"
    },
    {
        label: "Технологии разработки",
        value: "developer-stack"
    },
    {
        label: "Грэйды",
        value: "work-levels"
    },
    {
        label: "Города",
        value: "cities"
    },
    {
        label: "Регионы",
        value: "regions"
    },
    {
        label: "Страны",
        value: "countries"
    }
]