import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { INITIAL_STATE } from "./region.constants";
import { createRegionCases, getRegionListCases, updateRegionCases } from "./region.reducers";
import { TRootState } from "store";

const regionSlice = createSlice({
    initialState: INITIAL_STATE,
    name: "region",
    reducers: {
        setCreatingState(state, action) {
            state.creating = action.payload;
        },
        setUpdatingState(state, action) {
            state.updating = action.payload;
        },
        filterRegionsByUserCategory(state, action: PayloadAction<number>) {
            const items = state.items || [];        
            const mappedItems = items.map((r) => ({
                ...r,
                users: r.users?.filter(u => u.workGroupId === action.payload),
            }))   
            state.itemsFiltered = mappedItems.filter((r) => r.users?.length > 0);
        },
        resetRegionsFilters(state) {
            const items = state.items || [];  
            state.itemsFiltered = items;
        }
    },
    extraReducers(builder) {
        getRegionListCases(builder);
        createRegionCases(builder);
        updateRegionCases(builder);
    },
});

export const {
    setCreatingState,
    setUpdatingState,
    filterRegionsByUserCategory,
    resetRegionsFilters,
} = regionSlice.actions;

export default regionSlice.reducer;
export const selectRegionState = (state: TRootState) => state.region;