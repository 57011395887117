import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IStackState } from "./stack.types";
import { createStack, getStackList, updateStack } from "./stack.thunks";

export const getStackListCases = (
    builder: ActionReducerMapBuilder<IStackState>,
  ) => {
    builder.addCase(getStackList.pending, state => {
      state.loading = "loading";
      state.error = null;
    });
    builder.addCase(getStackList.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.items = action.payload.data;
    });
    builder.addCase(getStackList.rejected, (state, action) => {
      state.loading = "failed";
      state.error = action.payload?.message;
    });
  }; 

  export const createStackCases = (
    builder: ActionReducerMapBuilder<IStackState>,
  ) => {
    builder.addCase(createStack.pending, state => {
      state.creating = "loading";
      state.error = null;
    });
    builder.addCase(createStack.fulfilled, (state, action) => {
      state.creating = "succeeded";
    });
    builder.addCase(createStack.rejected, (state, action) => {
      state.creating = "failed";
      state.error = action.payload?.message;
    });
  };

  export const updateStackCases = (
    builder: ActionReducerMapBuilder<IStackState>,
  ) => {
    builder.addCase(updateStack.pending, state => {
      state.updating = "loading";
      state.error = null;
    });
    builder.addCase(updateStack.fulfilled, (state, action) => {
      state.updating = "succeeded";
    });
    builder.addCase(updateStack.rejected, (state, action) => {
      state.updating = "failed";
      state.error = action.payload?.message;
    });
  };    