import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { TAppDispatch, TRootState } from "store";
import { IGetRegionsParams, IRegion, IRegionCreateData } from "./region.types";
import { URL_REGION, URL_REGIONS } from "./region.api";
import apiCitizen from "helpers/apiCitizen";

export const getRegionList = createAsyncThunk<
  AxiosResponse<IRegion[]>,
  IGetRegionsParams,
  {
    dispatch: TAppDispatch;
    rejectValue: AxiosError;
    state: TRootState;
  }
>(
  "region/getRegionList",
  async (params, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
        return (await apiCitizen.get(URL_REGIONS, {params})) as AxiosResponse<IRegion[]>;
    } catch (error) {
        return rejectWithValue(error as AxiosError);
    } 
  },
);

export const createRegion = createAsyncThunk<
  AxiosResponse<IRegion>,
  IRegionCreateData,
  {
    dispatch: TAppDispatch;
    rejectValue: AxiosError;
    state: TRootState;
  }
>(
  "region/createRegion",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
        return (await apiCitizen.post(URL_REGIONS, data)) as AxiosResponse<IRegion>;
    } catch (error) {
        return rejectWithValue(error as AxiosError);
    } 
  },
);

export const updateRegion = createAsyncThunk<
  AxiosResponse<IRegion>,
  { data: IRegionCreateData, id: number},
  {
    dispatch: TAppDispatch;
    rejectValue: AxiosError;
    state: TRootState;
  }
>(
  "region/updateRegion",
  async ({data, id}, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const url = URL_REGION(id);
    try {
        return (await apiCitizen.patch(url, data)) as AxiosResponse<IRegion>;
    } catch (error) {
        return rejectWithValue(error as AxiosError);
    } 
  },
);