import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import { IProjectListProps } from './types';
import { ICountry } from 'entities/country/country.types';
import { IRegion } from 'entities/region/region.types';

import styles from "./ProjectsList.module.scss";
import { useAppSelector } from 'store';
import { IProject } from 'entities/project/project.types';
import { selectCountryState } from 'entities/country/country.slice';
import { selectRegionState } from 'entities/region/region.slice';

const ProjectList = ({ countryId, regionId, onItemClick }: IProjectListProps) => {
    const [country, setCountry] = useState<ICountry>(null);
    const [region, setRegion] = useState<IRegion>(null);

    const { items: countries_array } = useAppSelector(selectCountryState);
    const { items: regions_array } = useAppSelector(selectRegionState);
    
    const [list, setList] = useState<IProject[]>([]);

    useEffect(() => {
        if (countryId) {
            setCountry(countries_array.find(c => c.id === countryId));
        } else if (regionId) {
            setRegion(regions_array.find(c => c.id === regionId));
        }
    }, [countryId, regionId, regions_array, countries_array]);

    return (
        <List className={styles.list}
              subheader={<Typography className={styles.title} variant="h5" component="h5" align="center">
                            {country?.name || region?.title || ""}
                        </Typography>}>
            {list?.map((project, ind) => {
                const city = {name: "todo", timezone: "todo"};
                return (
                <>
                    <ListItem key={ind} alignItems="flex-start">
                        <ListItemText
                            primary={
                                <div className={styles.name} onClick={() => onItemClick(project)}>
                                    {project?.title}
                                </div>
                            }
                            secondary={
                                <React.Fragment>
                                <Typography
                                    component="div"
                                    variant="body2"
                                    color="CaptionText"
                                >
                                    {project?.description || ""}
                                </Typography>
                                <Typography
                                    component="div"
                                    variant="body2"
                                    color="GrayText"
                                >
                                    {city?.name || ''} {city?.timezone || ''}
                                </Typography>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                </>
            )})}
        </List>
    )
}

export default ProjectList;