import { IColumn } from "../types";
import { IDialogDeveloperGroupData } from "./types";

export const columns: IColumn[] = [
    { 
      id: 'id', 
      label: 'ID', 
      width: 90 
    },
    {
      id: 'name',
      label: 'Наименование',
      width: 250,
    },
    {
      id: 'actions',
      label: "",
    }
  ];

export const defaultDialogData: IDialogDeveloperGroupData = {
  open: false,
  devGroup: null,
}  