import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IAuthAdminState } from "./authAdmin.types";
import { signIn } from "./authAdmin.thunks";
import { setAdminTokenInLocalStorage } from "helpers/tokens";
import { getErrorFromAxios } from "helpers/errorHandlers";

export const signInCases = (
    builder: ActionReducerMapBuilder<IAuthAdminState>,
  ) => {
    builder.addCase(signIn.pending, state => {
      state.loading = "loading";
      state.error = null;
    });
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.loading = "succeeded";
      const token = action.payload?.data?.access_token;
      state.authorized = !!token;
      state.token = token;
      setAdminTokenInLocalStorage(token);
    });
    builder.addCase(signIn.rejected, (state, action) => {
      state.loading = "failed";
      state.error = getErrorFromAxios(action.payload);
    });
  }; 