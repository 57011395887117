import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";

import { IFormGroupCheckboxesProps } from "./types";

const FormGroupCheckboxes = ({ label, items, selectedValues = [], handleChange, valueField }: IFormGroupCheckboxesProps) => {
    return (
      <FormControl fullWidth sx={{ m: "16px 0" }} size="small" component="fieldset" variant="outlined">
        <FormLabel component="label">{label}</FormLabel>
        <FormGroup row sx={{
          overflowY: "auto", maxHeight: "200px"
        }}>
          {items?.map((item, index) => (
              <FormControlLabel
                key={`${item.name}_${index}`}
                control={
                  <Checkbox 
                    size="small"
                    value={item[valueField]}
                    checked={selectedValues?.includes(item[valueField])} 
                    onChange={handleChange}
                    />
                }
                name={item.name}
                label={item.name}
              />
          ))}
        </FormGroup>
      </FormControl>
    )
};

export default FormGroupCheckboxes;