import { IUserState } from "./user.types";

export const page = 1;

export const INITIAL_STATE: IUserState = {
    items: [],
    itemsFiltered: [],
    error: null,
    loading: 'idle',
};

export const peopleColors = {
    "Разработчики": "blue",
    "Аналитики": "violet",
    "Дизайнеры": "green",
    "Менеджеры": "pink",
    "HR": "blue-light",
    "Тестировщики": "peach",
    "ФПО": "orange",
}
