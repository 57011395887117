import { useEffect, useState, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store';

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

import { columns, defaultDialogData } from './constants';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@mui/material';

import { IDialogCityData } from "./types";
import styles from "./AdminCities.module.scss";
import RemoveItemModal from 'pages/admin/components/RemoveItemModal';
import CreateCityModal from './components/CreateCityModal';
import UpdateCityModal from './components/UpdateCityModal';
import { getCityList } from 'entities/city/city.thunks';
import { selectCityState, setCreatingState, setUpdatingState } from 'entities/city/city.slice';
import { ICity } from 'entities/city/city.types';
import { selectAdminState, setDeletingState } from 'entities/admin/admin.slice';
import useTablePagination from 'hooks/useTablePagination';

const AdminCities = () => {
  const {page, rowsPerPage, handleChangePage, handleChangeRowsPerPage} = useTablePagination();

  const [removeDialog, setRemoveDialog] = useState<IDialogCityData>(defaultDialogData);
  const [updateDialog, setUpdateDialog] = useState<IDialogCityData>(defaultDialogData);
  const [createDialog, setCreateDialog] = useState<boolean>(false);

  const {rows, creating, updating} = useAppSelector(selectCityState);
  const {deleting} = useAppSelector(selectAdminState);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCityList());
  }, [dispatch]);

  useEffect(() => {
    if (deleting.cities === "succeeded" || creating === "succeeded" || updating === "succeeded") {
      dispatch(getCityList());
    }
  }, [deleting.cities, creating, updating]);

  const handleAddClick = () => {
    dispatch(setCreatingState('idle'));
    setCreateDialog(true);
  }

  const handleCreateDialogClose = useCallback(() => {
    setCreateDialog(false);
  }, []);

  const handleRemoveClick = (city: ICity) => {
    dispatch(setDeletingState({entity: "cities", value: "idle"}));
    setRemoveDialog({ city, open: true });
  }

  const handleRemoveDialogClose = useCallback(() => {
    setRemoveDialog(defaultDialogData);
  }, []);

  const handleEditClick = (city: ICity) => {
    dispatch(setUpdatingState('idle'));
    setUpdateDialog({ city, open: true });
  }

  const handleUpdateDialogClose = useCallback(() => {
    setUpdateDialog(defaultDialogData);
  }, []);

  return (
    <>
  <Paper sx={{ width: '100%', height: '100%', overflow: 'hidden' }}>
    <div>
      <Button onClick={handleAddClick} size='large' startIcon={<AddIcon />}>
        Добавить город
      </Button>
    </div>

    <TableContainer sx={{ maxHeight: 720 }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ width: column.width }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.id === "actions" ? 
                          (<div className={styles.actionsCell}>
                            <EditIcon onClick={() => handleEditClick(row)} />
                            <DeleteIcon onClick={() => handleRemoveClick(row)}/>
                          </div>) 
                          : column.id === "userCount" ? (row.users?.length || 0) 
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[10, 25, 100]}
      component="div"
      count={rows.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  </Paper>

    {removeDialog.open && removeDialog.city &&
        <RemoveItemModal
          opened={removeDialog.open}
          onClose={handleRemoveDialogClose}
          id={removeDialog.city?.id}
          name={removeDialog.city?.name}
          entity="cities"
        />  
    }

    {createDialog &&
        <CreateCityModal
          opened={createDialog}
          onClose={handleCreateDialogClose}
        />  
    }

    {updateDialog.open && updateDialog.city &&
        <UpdateCityModal
          opened={updateDialog.open}
          onClose={handleUpdateDialogClose}
          data={updateDialog.city}
        />  
    }
  </>
  );
}

export default AdminCities;
