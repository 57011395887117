import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { TAppDispatch, TRootState } from "store";
import { ICity, ICityCreateData } from "./city.types";
import { URL_CITIES, URL_CITY } from "./city.api";
import apiCitizen from "helpers/apiCitizen";

export const getCityList = createAsyncThunk<
  AxiosResponse<ICity[]>,
  void,
  {
    dispatch: TAppDispatch;
    rejectValue: AxiosError;
    state: TRootState;
  }
>(
  "city/getCityList",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
        return (await apiCitizen.get(URL_CITIES)) as AxiosResponse<ICity[]>;
    } catch (error) {
        return rejectWithValue(error as AxiosError);
    } 
  },
);

export const createCity = createAsyncThunk<
  AxiosResponse<ICity>,
  ICityCreateData,
  {
    dispatch: TAppDispatch;
    rejectValue: AxiosError;
    state: TRootState;
  }
>(
  "city/createCity",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
        return (await apiCitizen.post(URL_CITIES, data)) as AxiosResponse<ICity>;
    } catch (error) {
        return rejectWithValue(error as AxiosError);
    } 
  },
);

export const updateCity = createAsyncThunk<
  AxiosResponse<ICity>,
  { data: ICityCreateData, id: number},
  {
    dispatch: TAppDispatch;
    rejectValue: AxiosError;
    state: TRootState;
  }
>(
  "city/updateCity",
  async ({data, id}, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const url = URL_CITY(id);
    try {
        return (await apiCitizen.patch(url, data)) as AxiosResponse<ICity>;
    } catch (error) {
        return rejectWithValue(error as AxiosError);
    } 
  },
);