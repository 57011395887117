import { ICitizenState } from "./citizen.types";

export const INITIAL_STATE: ICitizenState = {
    items: [],
    itemsMerged: [],
    rows: [],
    loading: "idle",
    creating: "idle",
    updating: "idle",
    error: null,
    adminUsers: {
        users: [],
        newUsers: [],
    },
}