import { useCallback, useState, memo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import { IEditDataModalProps } from 'pages/admin/types';
import { IDeveloperGroup, IDeveloperGroupCreateData } from 'entities/developerGroup/developerGroup.types';
import { selectDevGroupState } from 'entities/developerGroup/developerGroup.slice';
import { updateDevGroup } from 'entities/developerGroup/developerGroup.thunks';

const UpdateDevGroupModal = ({ data: devGroup, onClose, opened }: IEditDataModalProps<IDeveloperGroup>) => {
  const [data, setData] = useState<IDeveloperGroupCreateData>(null);  
  const { updating } = useAppSelector(selectDevGroupState);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (devGroup?.name) {
      setData({
        name: devGroup?.name,
      });
    }
  }, [devGroup]);

  const onConfirmUpdate = () => {
    if (devGroup?.id && data?.name) {
        dispatch(updateDevGroup({ id: devGroup.id, data }));
    }
  }  

  const handleDataChange = useCallback((e) => {
    setData(oldData => ({ ...oldData, [e.target.name]: e.target.value}));
  }, []);

  return (
      <Dialog
        open={opened}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Обновление компетенции"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              updating === "succeeded" 
              ? 'Данные компетенции успешно обновлены' 
              : 'Заполните обязательные поля'
            }
          </DialogContentText>
          {updating !== "succeeded" && <>
            <TextField
              margin="normal"
              name="name"
              label="Наименование"
              fullWidth
              variant="outlined"
              value={data?.name}
              onChange={handleDataChange}
            />
          </>}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
          {updating === "succeeded" ? 'Закрыть' : 'Отмена'}
          </Button>
          {updating !== "succeeded" &&
            <Button onClick={onConfirmUpdate} autoFocus>
              Сохранить
            </Button>
          }
        </DialogActions>
      </Dialog>
  );
}

export default memo(UpdateDevGroupModal);
