import {ReactComponent as AvatarBlue} from "svg/avatars/avatar-blue.svg";
import {ReactComponent as AvatarGreen} from "svg/avatars/avatar-green.svg";
import {ReactComponent as AvatarOrange} from "svg/avatars/avatar-orange.svg";
import {ReactComponent as AvatarPink} from "svg/avatars/avatar-pink.svg";
import {ReactComponent as AvatarViolet} from "svg/avatars/avatar-violet.svg";
import {ReactComponent as AvatarBlueLight} from "svg/avatars/avatar-blue-light.svg";
import {ReactComponent as AvatarPeach} from "svg/avatars/avatar-peach.svg";
import { memo } from "react";
import { IUserIconProps } from "./types";

const UserIcon = (props: IUserIconProps) => {
    switch (props.category) {
        case "Аналитики": return <AvatarViolet />;
        case "Дизайнеры": return <AvatarGreen />;
        case "Менеджеры": return <AvatarPink />;
        case "HR": return <AvatarBlueLight />;
        case "Разработчики": return <AvatarBlue />;
        case "Тестировщики": return <AvatarPeach />;
        default: return <AvatarOrange />;
    }
}

export default memo(UserIcon);