import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { INITIAL_STATE } from "./country.constants";
import { createCountryCases, getCountryListCases, updateCountryCases } from "./country.reducers";
import { TRootState } from "store";

const countrySlice = createSlice({
    initialState: INITIAL_STATE,
    name: "country",
    reducers: {
        setCreatingState(state, action) {
            state.creating = action.payload;
        },
        setUpdatingState(state, action) {
            state.updating = action.payload;
        },
        filterCountriesByUserCategory(state, action: PayloadAction<number>) {
            const items = state.items || [];            
            const mappedItems = items.map((r) => ({
                ...r,
                users: r.users?.filter(u => u.workGroupId === action.payload),
            }))   
            state.itemsFiltered = mappedItems.filter((r) => r.users?.length > 0);
        },
        resetCountriesFilters(state) {
            const items = state.items || [];  
            state.itemsFiltered = items;
        }
    },
    extraReducers(builder) {
        getCountryListCases(builder);
        createCountryCases(builder);
        updateCountryCases(builder);
    },
});

export const {
    setCreatingState,
    setUpdatingState,
    filterCountriesByUserCategory,
    resetCountriesFilters,
} = countrySlice.actions;

export default countrySlice.reducer;
export const selectCountryState = (state: TRootState) => state.country;