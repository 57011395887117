import { useCallback, useState, memo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import { selectCountryState } from 'entities/country/country.slice';
import { ICountry, ICountryCreateData } from 'entities/country/country.types';
import { updateCountry } from 'entities/country/country.thunks';
import { IEditDataModalProps } from 'pages/admin/types';

const UpdateCountryModal = ({ data: country, onClose, opened }: IEditDataModalProps<ICountry>) => {
  const [data, setData] = useState<ICountryCreateData>(null);  
  const { updating } = useAppSelector(selectCountryState);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (country?.value) {
      setData({
        name: country?.name,
        title: country?.title,
        value: country?.value,
      });
    }
  }, [country]);

  const onConfirmUpdate = () => {
    if (country?.id && data?.value) {
        dispatch(updateCountry({ id: country.id, data }));
    }
  }  

  const handleDataChange = useCallback((e) => {
    setData(oldData => ({ ...oldData, [e.target.name]: e.target.value}));
  }, []);

  return (
      <Dialog
        open={opened}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Обновление страны"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              updating === "succeeded" 
              ? 'Данные страны успешно обновлены' 
              : 'Заполните обязательные поля'
            }
          </DialogContentText>
          {updating !== "succeeded" && <>
          <TextField
            margin="normal"
            name="name"
            label="Наименование"
            fullWidth
            variant="outlined"
            value={data?.name}
            onChange={handleDataChange}
          /> 
          <TextField
            margin="normal"
            name="title"
            label="Наименование (EN)"
            fullWidth
            variant="outlined"
            value={data?.title}
            onChange={handleDataChange}
          /> 
          <TextField
            margin="normal"
            name="value"
            label="ID векторного объекта"
            fullWidth
            variant="outlined"
            value={data?.value}
            onChange={handleDataChange}
          /> </>}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
          {updating === "succeeded" ? 'Закрыть' : 'Отмена'}
          </Button>
          {updating !== "succeeded" &&
            <Button onClick={onConfirmUpdate} autoFocus>
              Сохранить
            </Button>
          }
        </DialogActions>
      </Dialog>
  );
}

export default memo(UpdateCountryModal);
