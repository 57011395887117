import { useState, useCallback, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";

import Drawer from "@mui/material/Drawer";
import Modal from '@mui/material/Modal';
import MapCountry from "components/MapCountry";
import UserList from "components/UserList";
import ProjectList from "components/ProjectList";
import MapContainer from "containers/MapContainer";
import UserCard from "components/UserCard";
import MapLoader from "components/MapLoader";

import { useAppDispatch, useAppSelector } from "store";

import { mapSelector, setMapView } from "entities/map/map.slice";
import { IProject } from "entities/project/project.types";
import { ICitizenUserMergedWithHub } from "entities/citizen/citizen.types";
import { getGradeList } from "entities/grade/grade.thunks";
import { getRegionList } from "entities/region/region.thunks";
import { filterRegionsByUserCategory, resetRegionsFilters, selectRegionState } from "entities/region/region.slice";
import { getCitizenUsersMerged } from "entities/citizen/citizen.thunks";

const CountryPage = () => {
    const { keycloak } = useKeycloak();
    const [drawerOpened, setDrawerOpened] = useState(false);
    const [currentRegionId, setCurrentRegionId] = useState(null);

    const { loading: loadingRegions } = useAppSelector(selectRegionState);

    const [currentUser, setCurrentUser] = useState<ICitizenUserMergedWithHub | null>(null);
    const [currentProject, setCurrentProject] = useState(null);
    const [countryId, setCountryId] = useState("RU");

    const {view} = useAppSelector(mapSelector);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (countryId && keycloak?.authenticated) {
            dispatch(getGradeList());
            dispatch(getRegionList({countryId}));
            dispatch(getCitizenUsersMerged({ countryId }));
            dispatch(setMapView("count"));
        }
    }, [countryId, keycloak?.authenticated]);
  
    const toggleDrawer = useCallback((region_id) => {
        setCurrentUser(null);
        setCurrentProject(null);
        setCurrentRegionId(drawerOpened ? null : region_id);
        setDrawerOpened((oldValue) => !oldValue)
    }, []);

    const onSelectUser = useCallback((user: ICitizenUserMergedWithHub) => {
        setCurrentUser(user);
        setCurrentProject(null);
    }, [currentUser, currentProject]);

    const onSelectProject = useCallback((p: IProject) => {
        setCurrentUser(null);
        setCurrentProject(p);
    }, [currentUser, currentProject]);

    const onFilterUsersByGroup = useCallback((id: number | null) => {
        if (id) {
            dispatch(filterRegionsByUserCategory(id));
        } else {
            dispatch(resetRegionsFilters());
        }
        dispatch(setMapView("count"));
    }, []);

    const onFilterUsersByProject = useCallback((sourceId: string | null) => {
        if (sourceId) {
            const project = sourceId ? { "userParams.project": sourceId } : null;
            dispatch(getCitizenUsersMerged({countryId, ...project}));
            dispatch(setMapView("people"));
        } else {
            dispatch(setMapView("count"));
        }
    }, []);
    
    return (
        <>
        {(loadingRegions === "loading") && <MapLoader />}
        <MapContainer 
            title="RUSSIA" 
            onFilterUsersByGroup={onFilterUsersByGroup}
            onFilterUsersByProject={onFilterUsersByProject}
        >    
            <MapCountry onPinClick={toggleDrawer} onPinUserClick={setCurrentUser}/>
    
            <Drawer
                anchor="right"
                open={drawerOpened}
                onClose={toggleDrawer}
            >
                {view === "count" &&
                    <UserList regionId={currentRegionId} onItemClick={onSelectUser}/>
                }
                {view === "projects" &&
                    <ProjectList regionId={currentRegionId} onItemClick={onSelectProject} />
                }
            </Drawer>

            {currentUser?.email &&
                <Modal open={true} onClose={() => setCurrentUser(null)}>
                    <UserCard user={currentUser}/>
                </Modal>
            }
        </MapContainer>
        </>
    )
}

export default CountryPage;

