import Typography from '@mui/material/Typography';
import LanguageIcon from '@mui/icons-material/Language';
import Link from '@mui/material/Link';
import styles from './404.module.scss';
import { routes } from 'router/routes';

const Page404 = () => {
  return (
    <div className={styles.container}>
      <Typography color="GrayText" variant='h1' sx={{ fontWeight: 600, display: "flex", alignItems: "center" }}>
        4<LanguageIcon sx={{ fontSize: "5.5rem" }}/>4
      </Typography>
      <Typography color="GrayText" variant='h5'>Ты перешёл все границы, дружочек...</Typography>
      <Typography color="GrayText" variant='h5'>Возвращайся в <Link color="inherit" href={routes.WORLD}>crt.world</Link></Typography>
    </div>
  );
};

export default Page404;
