import { useCallback, useState, memo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import { selectWorkLevelState } from 'entities/workLevel/workLevel.slice';
import { IPostWorkLevelData } from 'entities/workLevel/workLevel.types';
import { createWorkLevel } from 'entities/workLevel/workLevel.thunks';
import { selectDevGroupState } from 'entities/developerGroup/developerGroup.slice';
import { selectGradeState } from 'entities/grade/grade.slice';
import { IDeveloperGroup } from 'entities/developerGroup/developerGroup.types';
import { IGrade } from 'entities/grade/grade.types';
import { getDevGroupList } from 'entities/developerGroup/developerGroup.thunks';
import { getGradeList } from 'entities/grade/grade.thunks';
import { IEditDataModalProps } from 'pages/admin/types';
import { selectWorkGroupState } from 'entities/workGroup/workGroup.slice';
import { getWorkGroupList } from 'entities/workGroup/workGroup.thunks';
import { IWorkGroup } from 'entities/workGroup/workGroup.types';

const CreateWorkLevelModal = ({ onClose, opened }: IEditDataModalProps<unknown>) => {
  const defaultData: IPostWorkLevelData = { 
   gradeId: null,
   groupId: null,
   workGroupId: null,
  };
  const [data, setData] = useState<IPostWorkLevelData>(defaultData);  

  const { creating } = useAppSelector(selectWorkLevelState);
  const { items: groups } = useAppSelector(selectDevGroupState);
  const { items: grades } = useAppSelector(selectGradeState);
  const { items: workGroups } = useAppSelector(selectWorkGroupState);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getDevGroupList());
    dispatch(getGradeList());
    dispatch(getWorkGroupList());
  }, []);

  const onConfirmCreate = () => {
    if (data?.gradeId && data?.groupId) {
        dispatch(createWorkLevel(data));
    }
  }  

  const handleGroupChange = useCallback((e: SelectChangeEvent) => {
    setData(oldData => ({ ...oldData, groupId: Number(e.target.value)}));
  }, []);

  const handleGradeChange = useCallback((e: SelectChangeEvent) => {
    setData(oldData => ({ ...oldData, gradeId: Number(e.target.value)}));
  }, []);

  const handleWorkGroupChange = useCallback((e: SelectChangeEvent) => {
    setData(oldData => ({ ...oldData, workGroupId: Number(e.target.value)}));
  }, []);

  return (
      <Dialog
        open={opened}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Добавление проф уровня"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              creating === "succeeded" 
              ? 'Проф уровень успешно добавлен' 
              : 'Заполните обязательные поля'
            }
          </DialogContentText>

{creating !== "succeeded" && <>
          <FormControl margin="normal" fullWidth>
            <InputLabel id="select-label-group">Группа</InputLabel>
            <Select
              labelId="select-label-group"
              name="groupId"
              value={data?.groupId ? data?.groupId.toString() : null}
              fullWidth
              onChange={handleGroupChange}
              variant='outlined'
            >
              <MenuItem value={null}>-- Не выбрана --</MenuItem>
              {groups?.map((c: IDeveloperGroup) => <MenuItem value={c.id}>{c.name}</MenuItem>)}
            </Select>
          </FormControl>

          <FormControl margin="normal" fullWidth>
            <InputLabel id="select-label-grade">Уровень</InputLabel>
            <Select
              labelId="select-label-grade"
              name="gradeId"
              value={data?.gradeId ? data?.gradeId.toString() : null}
              fullWidth
              onChange={handleGradeChange}
              variant='outlined'
            >
              <MenuItem value={null}>-- Не выбран --</MenuItem>
              {grades?.map((c: IGrade) => <MenuItem value={c.id}>{c.name}</MenuItem>)}
            </Select>
          </FormControl>

          <FormControl margin="normal" fullWidth>
            <InputLabel id="select-label-work-group">Категория</InputLabel>
            <Select
              labelId="select-label-work-group"
              name="workGroupId"
              value={data?.workGroupId ? data?.workGroupId.toString() : null}
              fullWidth
              onChange={handleWorkGroupChange}
              variant='outlined'
            >
              <MenuItem value={null}>-- Не выбрана --</MenuItem>
              {workGroups?.map((c: IWorkGroup) => <MenuItem value={c.id}>{c.name}</MenuItem>)}
            </Select>
          </FormControl>
</>}

        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
          {creating === "succeeded" ? 'Закрыть' : 'Отмена'}
          </Button>
          {creating !== "succeeded" &&
            <Button onClick={onConfirmCreate} autoFocus>
              Сохранить
            </Button>
          }
        </DialogActions>
      </Dialog>
  );
}

export default memo(CreateWorkLevelModal);
