import { IColumn } from "../types";
import { IDialogCityData } from "./types";

export const columns: IColumn[] = [
    { 
      id: 'id', 
      label: 'ID', 
      width: 90 
    },
    {
      id: 'name',
      label: 'Наименование',
      width: 250,
    },
    {
      id: 'title',
      label: 'Наименование (EN)',
      width: 250,
    },
    {
      id: 'value',
      label: 'ID Path',
      width: 150,
    },
    {
      id: 'timezone',
      label: 'Часовой пояс',
      width: 150,
    },
    {
      id: 'region',
      label: 'Регион',
      width: 150,
    },
    {
      id: 'country',
      label: 'Страна',
      width: 150,
    },
    {
      id: 'userCount',
      label: 'Users',
      width: 100,
    },
    {
      id: 'actions',
      label: "",
    }
  ];

export const defaultDialogData: IDialogCityData = {
  open: false,
  city: null,
}  