import { useEffect, useRef } from "react";
import * as d3 from "d3";

import {ReactComponent as Map} from "svg/russia.svg";
import styles from "./MapCountry.module.scss";
import { useAppSelector } from "store";

import { mapSelector } from "entities/map/map.slice";
import { selectRegionState } from "entities/region/region.slice";
import { selectCitizenState } from "entities/citizen/citizen.slice";

import { IRegion } from "entities/region/region.types";
import { IMapCountryProps } from "./types";

import { drawUser } from "draw/drawUser";
import { drawCount } from "draw/drawCount";

import { computeDimensions } from "helpers/computeDimensions";
import { getCenterOfDimensions } from "helpers/getCenterOfDimensions";
import { randomIntFromInterval } from "helpers/randomIntFromInterval";

const MapCountry = ({onPinClick, onPinUserClick}: IMapCountryProps) => {
    const refMap = useRef();
    const {view} = useAppSelector(mapSelector);

    const { itemsFiltered: crt_regions } = useAppSelector(selectRegionState);
    const { itemsMerged: crt_users } = useAppSelector(selectCitizenState);

    const resetMap = () => {
        const svg = d3.select("#svg_map");
        svg.selectAll(`path`).attr("class", null);
        svg.selectAll("g").remove();    
    };

    useEffect(() => {
        resetMap();

        if (refMap?.current) {
            const svg = d3.select("#svg_map");
            var defs = svg.append('svg:defs');

            crt_regions.forEach((region: IRegion) => {
                const path = svg.select(`#${region.value}`);

                if (path) {
                    const dimensions = computeDimensions(path, region?.title);
                    path.attr("class", styles.crtRegion);
                    if (dimensions) {
                        const { x_center, y_center } = getCenterOfDimensions(dimensions);

                        if (view === "people") {
                            const region_users = crt_users?.filter(u => u?.city?.region?.value === region.value);

                            region_users.forEach((user) => {
                                const offset_x = dimensions.width > 60 ? randomIntFromInterval(-20, 20) : randomIntFromInterval(-5, 5);
                                const offset_y = dimensions.height > 60 ? randomIntFromInterval(-20, 20) : randomIntFromInterval(-5, 5);

                                drawUser(svg, defs, x_center, y_center, offset_x, offset_y, user.email, user.initials, () => onPinUserClick(user));
                            });
                        } else if (view === "count" || view === "projects") {
                            drawCount(svg, x_center, y_center, region.value, region.users?.length, () => onPinClick(region.value));
                        }
                    }
                }
            });
        }
    }, [refMap, crt_regions, crt_users, view]);   

    return (
        <div className={styles.container}>
            <Map className={styles.map} ref={refMap}/>
        </div>
    )
}

export default MapCountry;