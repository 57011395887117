import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IWorkLevelState } from "./workLevel.types";
import { createWorkLevel, getWorkLevelList, updateWorkLevel } from "./workLevel.thunks";

export const getWorkLevelListCases = (
    builder: ActionReducerMapBuilder<IWorkLevelState>,
  ) => {
    builder.addCase(getWorkLevelList.pending, state => {
      state.loading = "loading";
      state.error = null;
    });
    builder.addCase(getWorkLevelList.fulfilled, (state, action) => {
      const data = action.payload?.data || [];
      state.loading = "succeeded";
      state.items = data;
      state.rows = data?.map((item) => ({
        ...item,
        name: `${item.group?.name} - ${item.grade?.name}`,
      }))
    });
    builder.addCase(getWorkLevelList.rejected, (state, action) => {
      state.loading = "failed";
      state.error = action.payload?.message;
    });
  }; 

  export const createWorkLevelCases = (
    builder: ActionReducerMapBuilder<IWorkLevelState>,
  ) => {
    builder.addCase(createWorkLevel.pending, state => {
      state.creating = "loading";
      state.error = null;
    });
    builder.addCase(createWorkLevel.fulfilled, (state, action) => {
      state.creating = "succeeded";
    });
    builder.addCase(createWorkLevel.rejected, (state, action) => {
      state.creating = "failed";
      state.error = action.payload?.message;
    });
  };

  export const updateWorkLevelCases = (
    builder: ActionReducerMapBuilder<IWorkLevelState>,
  ) => {
    builder.addCase(updateWorkLevel.pending, state => {
      state.updating = "loading";
      state.error = null;
    });
    builder.addCase(updateWorkLevel.fulfilled, (state, action) => {
      state.updating = "succeeded";
    });
    builder.addCase(updateWorkLevel.rejected, (state, action) => {
      state.updating = "failed";
      state.error = action.payload?.message;
    });
  };  