import { useNavigate } from 'react-router';
import { useCallback } from 'react';
import { useKeycloak } from '@react-keycloak/web';

import Typography from '@mui/material/Typography';
import LanguageIcon from '@mui/icons-material/Language';
import Link from '@mui/material/Link';

import styles from './403.module.scss';
import { routes } from 'router/routes';

const Page403 = () => {
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();

  const onGoBackClick = useCallback(() => {
    keycloak?.logout().then(() => navigate(routes.HOME));
  }, []);

  return (
    <div className={styles.container}>
      <Typography color="GrayText" variant='h1' sx={{ fontWeight: 600, display: "flex", alignItems: "center" }}>
        4<LanguageIcon sx={{ fontSize: "5.5rem" }}/>3
      </Typography>
      <Typography color="GrayText" variant='h5'>Отказано в доступе</Typography>
      <Typography color="GrayText" variant='h6'>К сожалению, у вас нет прав для просмотра данной страницы...</Typography>
      <Typography color="GrayText" variant='h6'><Link color="inherit" onClick={onGoBackClick}>Вернуться на главную</Link></Typography>
    </div>
  );
};

export default Page403;
