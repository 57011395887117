import { IAuthState } from "./auth.types";

export const TOKEN_KEY = 'token';
export const REFRESH_TOKEN_KEY = 'refreshToken';

export const initialState: IAuthState = {
    loading: 'idle',
    error: null,
    token: null,
    refreshToken: null,
};

