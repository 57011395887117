import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';
import { FC, ReactNode } from 'react';
import env from '../config';
import { TOKEN_KEY } from 'entities/auth/auth.constants';

interface IKeycloakProvider {
  children: ReactNode;
}

const keycloak = new Keycloak({
  url: env.REACT_APP_AUTH_CRT_URL,
  realm: env.REACT_APP_AUTH_CRT_REALM || 'creative',
  clientId: env.REACT_APP_AUTH_CRT_CLIENTID || 'ihub',
})

export const KeycloakProvider: FC<IKeycloakProvider> = ({ children }) => {
  const handleEvent = (event: string) => {
    if (event === 'onAuthSuccess') {
      localStorage.setItem(TOKEN_KEY, keycloak.token!);
    }
    if (event === 'onAuthRefreshSuccess') {
      localStorage.setItem(TOKEN_KEY, keycloak.token!);
    }
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      onEvent={handleEvent}
    >
      {children}
    </ReactKeycloakProvider>
  );
};