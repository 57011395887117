import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { TAppDispatch, TRootState } from "store";
import { ICountry, ICountryCreateData, IGetCountriesParams } from "./country.types";
import { URL_COUNTRIES, URL_COUNTRY } from "./country.api";
import apiCitizen from "helpers/apiCitizen";

export const getCountryList = createAsyncThunk<
  AxiosResponse<ICountry[]>,
  IGetCountriesParams,
  {
    dispatch: TAppDispatch;
    rejectValue: AxiosError;
    state: TRootState;
  }
>(
  "country/getCountryList",
  async (params, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
        return (await apiCitizen.get(URL_COUNTRIES, { params })) as AxiosResponse<ICountry[]>;
    } catch (error) {
        return rejectWithValue(error as AxiosError);
    } 
  },
);

export const createCountry = createAsyncThunk<
  AxiosResponse<ICountry>,
  ICountryCreateData,
  {
    dispatch: TAppDispatch;
    rejectValue: AxiosError;
    state: TRootState;
  }
>(
  "country/createCountry",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
        return (await apiCitizen.post(URL_COUNTRIES, data)) as AxiosResponse<ICountry>;
    } catch (error) {
        return rejectWithValue(error as AxiosError);
    } 
  },
);

export const updateCountry = createAsyncThunk<
  AxiosResponse<ICountry>,
  { data: ICountryCreateData, id: number},
  {
    dispatch: TAppDispatch;
    rejectValue: AxiosError;
    state: TRootState;
  }
>(
  "country/updateCountry",
  async ({data, id}, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const url = URL_COUNTRY(id);
    try {
        return (await apiCitizen.patch(url, data)) as AxiosResponse<ICountry>;
    } catch (error) {
        return rejectWithValue(error as AxiosError);
    } 
  },
);