import { useCallback, useState } from "react";
import cn from "classnames";
import { useAppSelector } from "store";

import { mapSelector } from "entities/map/map.slice";
import { IProject } from "entities/project/project.types";
import { IToolsPanelProps } from "./types";

import UserIcon from "components/UserIcon";
import SettingsPanel from "components/SettingsPanel";
import ToolFilter from "./ToolFilter/ToolFilter";

import CloseIcon from '@mui/icons-material/Close';

import styles from "./ToolsPanel.module.scss";

const ToolsPanel = ({
    open,
    className, 
    categories, 
    projects, 
    onFilterByTeam, 
    onFilterByProject, 
    onViewChange,
    onClosePanel,
}: IToolsPanelProps) => {
    const [selectedType, setSelectedType] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const {view} = useAppSelector(mapSelector);

    const onPeopleTypeClick = useCallback((value) => {
        setSelectedProject(null);
        setSelectedType(value);
        onFilterByTeam?.(value);
    }, []);

    const onProjectClick = useCallback((value) => {
        setSelectedType(null);
        setSelectedProject(value);
        onFilterByProject?.(value);
    }, []);

    return (
        <div className={cn(className, styles.toolsPanel, {[styles.open]: open})}>
            <button className={styles.closeButton} onClick={onClosePanel}>
                <CloseIcon fontSize="small"/>
            </button>
            <SettingsPanel onViewChange={onViewChange}/>

            {view !== "projects" && <>
                {categories?.length > 0 &&
                    <ToolFilter 
                        title="Кланы" 
                        items={categories?.map(group => ({
                            ...group,
                            icon: <UserIcon category={group.name}/>,
                        }))} 
                        selected={selectedType} 
                        onItemClick={onPeopleTypeClick} 
                    />            
                }

                {projects?.length > 0 &&
                    <ToolFilter 
                        title="Мои проекты" 
                        items={projects?.map((p: IProject) => ({
                            id: p["@id"],
                            name: p.title,
                        }))} 
                        selected={selectedProject} 
                        onItemClick={onProjectClick}
                    />
                }
            </>}
      </div>
    )
};

export default ToolsPanel;