import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { ICityState } from "./city.types";
import { createCity, getCityList, updateCity } from "./city.thunks";

export const getCityListCases = (
    builder: ActionReducerMapBuilder<ICityState>,
  ) => {
    builder.addCase(getCityList.pending, state => {
      state.loading = "loading";
      state.error = null;
    });
    builder.addCase(getCityList.fulfilled, (state, action) => {
      const cities = action.payload.data;
      state.loading = "succeeded";
      state.items = cities || [];
      state.rows = cities?.map(city => ({
        ...city,
        country: city?.country?.name,
        region: city?.region?.title,
      }));
    });
    builder.addCase(getCityList.rejected, (state, action) => {
      state.loading = "failed";
      state.error = action.payload?.message;
    });
  };  

  export const createCityCases = (
    builder: ActionReducerMapBuilder<ICityState>,
  ) => {
    builder.addCase(createCity.pending, state => {
      state.creating = "loading";
      state.error = null;
    });
    builder.addCase(createCity.fulfilled, (state, action) => {
      state.creating = "succeeded";
    });
    builder.addCase(createCity.rejected, (state, action) => {
      state.creating = "failed";
      state.error = action.payload?.message;
    });
  };

  export const updateCityCases = (
    builder: ActionReducerMapBuilder<ICityState>,
  ) => {
    builder.addCase(updateCity.pending, state => {
      state.updating = "loading";
      state.error = null;
    });
    builder.addCase(updateCity.fulfilled, (state, action) => {
      state.updating = "succeeded";
    });
    builder.addCase(updateCity.rejected, (state, action) => {
      state.updating = "failed";
      state.error = action.payload?.message;
    });
  };  