import { createSlice } from "@reduxjs/toolkit";
import { INITIAL_STATE } from "./citizen.constants";
import { createCitizenCases, getCitizenUsersCases, getCitizenUsersMergedCases, updateCitizenCases } from "./citizen.reducers";
import { TRootState } from "store";

const citizenSlice = createSlice({
    initialState: INITIAL_STATE,
    name: "citizen",
    reducers: {
        setCreatingState(state, action) {
            state.creating = action.payload;
        },
        setUpdatingState(state, action) {
            state.updating = action.payload;
        }
    },
    extraReducers(builder) {
        getCitizenUsersCases(builder);
        getCitizenUsersMergedCases(builder);
        updateCitizenCases(builder);
        createCitizenCases(builder);
    },
});

export const {
    setCreatingState,
    setUpdatingState,
} = citizenSlice.actions;

export default citizenSlice.reducer;
export const selectCitizenState = (state: TRootState) => state.citizen;