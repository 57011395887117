import { memo } from "react";
import { IGradeImageProps } from "./types";
import useGradeImage from "./useGradeImage";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";

const GradeImage = ({ className, fileName, alt }: IGradeImageProps) => {
    const { error, image } = useGradeImage({fileName});

    return (
        <Tooltip arrow followCursor title={alt} placement="top">
            {(!image || error) ? 
            <Chip sx={{ marginRight: "4px", marginBottom: "4px" }} label={alt} color="default"/> :
            <img className={className} src={image} alt={alt} />}
        </Tooltip>
    );
}

export default memo(GradeImage);