import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { ICountryState } from "./country.types";
import { createCountry, getCountryList, updateCountry } from "./country.thunks";

export const getCountryListCases = (
    builder: ActionReducerMapBuilder<ICountryState>,
  ) => {
    builder.addCase(getCountryList.pending, state => {
      state.loading = "loading";
      state.error = null;
    });
    builder.addCase(getCountryList.fulfilled, (state, action) => {     
      const data = action.payload.data;
      const items = action.meta.arg.withEmpty ? data : data?.filter(item => item?.users?.length > 0);
      state.items = items;
      state.itemsFiltered = items;
      state.loading = "succeeded";
    });
    builder.addCase(getCountryList.rejected, (state, action) => {
      state.loading = "failed";
      state.error = action.payload?.message;
    });
  }; 

  export const createCountryCases = (
    builder: ActionReducerMapBuilder<ICountryState>,
  ) => {
    builder.addCase(createCountry.pending, state => {
      state.creating = "loading";
      state.error = null;
    });
    builder.addCase(createCountry.fulfilled, (state, action) => {
      state.creating = "succeeded";
    });
    builder.addCase(createCountry.rejected, (state, action) => {
      state.creating = "failed";
      state.error = action.payload?.message;
    });
  };

  export const updateCountryCases = (
    builder: ActionReducerMapBuilder<ICountryState>,
  ) => {
    builder.addCase(updateCountry.pending, state => {
      state.updating = "loading";
      state.error = null;
    });
    builder.addCase(updateCountry.fulfilled, (state, action) => {
      state.updating = "succeeded";
    });
    builder.addCase(updateCountry.rejected, (state, action) => {
      state.updating = "failed";
      state.error = action.payload?.message;
    });
  };    