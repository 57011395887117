import { useEffect, useState, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store';

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

import { columns, defaultDialogData } from './constants';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@mui/material';

import { IDialogWorkLevelData } from "./types";
import styles from "./AdminWorkLevels.module.scss";
import RemoveItemModal from 'pages/admin/components/RemoveItemModal';
import CreateWorkLevelModal from './components/CreateWorkLevelModal';
import UpdateWorkLevelModal from './components/UpdateWorkLevelModal';

import { selectWorkLevelState, setCreatingState, setUpdatingState } from 'entities/workLevel/workLevel.slice';
import { getWorkLevelList } from 'entities/workLevel/workLevel.thunks';
import { IWorkLevel, IWorkLevelRow } from 'entities/workLevel/workLevel.types';
import { selectAdminState, setDeletingState } from 'entities/admin/admin.slice';
import useTablePagination from 'hooks/useTablePagination';

const AdminWorkLevels = () => {
  const {page, rowsPerPage, handleChangePage, handleChangeRowsPerPage} = useTablePagination();

  const [removeDialog, setRemoveDialog] = useState<IDialogWorkLevelData>(defaultDialogData);
  const [updateDialog, setUpdateDialog] = useState<IDialogWorkLevelData>(defaultDialogData);
  const [createDialog, setCreateDialog] = useState<boolean>(false);

  const {rows, creating, updating} = useAppSelector(selectWorkLevelState);
  const {deleting} = useAppSelector(selectAdminState);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getWorkLevelList({}));
  }, [dispatch]);

  useEffect(() => {
    if (deleting["work-levels"] === "succeeded" || creating === "succeeded" || updating === "succeeded") {
      dispatch(getWorkLevelList({}));
    }
  }, [deleting, creating, updating]);

  const handleAddClick = () => {
    dispatch(setCreatingState('idle'));
    setCreateDialog(true);
  }

  const handleCreateDialogClose = useCallback(() => {
    setCreateDialog(false);
  }, []);

  const handleRemoveClick = (workLevel: IWorkLevel) => {
    dispatch(setDeletingState({ entity: 'work-levels', value: 'idle' }));
    setRemoveDialog({ workLevel, open: true });
  }

  const handleRemoveDialogClose = useCallback(() => {
    setRemoveDialog(defaultDialogData);
  }, []);

  const handleEditClick = (workLevel: IWorkLevel) => {
    dispatch(setUpdatingState('idle'));
    setUpdateDialog({ workLevel, open: true });
  }

  const handleUpdateDialogClose = useCallback(() => {
    setUpdateDialog(defaultDialogData);
  }, []);

  return (
    <>
  <Paper sx={{ width: '100%', height: '100%', overflow: 'hidden' }}>
    <div>
      <Button onClick={handleAddClick} size='large' startIcon={<AddIcon />}>
        Добавить проф уровень
      </Button>
    </div>

    <TableContainer sx={{ maxHeight: 720 }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ width: column.width }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row: IWorkLevelRow) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row?.id}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.id === "actions" ? 
                          (<div className={styles.actionsCell}>
                            <EditIcon onClick={() => handleEditClick(row)} />
                            <DeleteIcon onClick={() => handleRemoveClick(row)}/>
                          </div>) 
                          : column.id === "workGroup" ? row.workGroup?.name : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[10, 25, 100]}
      component="div"
      count={rows.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  </Paper>

    {removeDialog.open && removeDialog.workLevel &&
        <RemoveItemModal
          opened={removeDialog.open}
          onClose={handleRemoveDialogClose}
          id={removeDialog.workLevel.id}
          name={`${removeDialog.workLevel.group?.name}-${removeDialog.workLevel.grade?.name}`}
          entity='work-levels'
        />  
    }

    {createDialog &&
        <CreateWorkLevelModal
          opened={createDialog}
          onClose={handleCreateDialogClose}
        />  
    }

    {updateDialog.open && updateDialog.workLevel &&
        <UpdateWorkLevelModal
          opened={updateDialog.open}
          onClose={handleUpdateDialogClose}
          data={updateDialog.workLevel}
        />  
    }
  </>
  );
}

export default AdminWorkLevels;
