import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { TAppDispatch, TRootState } from "store";
import { IAdminDeleteItemParams } from "./admin.types";
import apiCitizen from "helpers/apiCitizen";

export const deleteItem = createAsyncThunk<
  AxiosResponse,
  IAdminDeleteItemParams,
  {
    dispatch: TAppDispatch;
    rejectValue: AxiosError;
    state: TRootState;
  }
>(
  "admin/deleteItem",
  async ({itemId, entityName}, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const url = `/${entityName}/${itemId}`;
    try {
        return (await apiCitizen.delete(url)) as AxiosResponse;
    } catch (error) {
        return rejectWithValue(error as AxiosError);
    } 
  },
);