import { memo } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useAppDispatch, useAppSelector } from 'store';
import { IRemoveItemModalProps } from './types';

import { selectAdminState } from 'entities/admin/admin.slice';
import { deleteItem } from 'entities/admin/admin.thunks';
import { ENTITY_TITLE, ENTITY_TITLE_ROD } from 'entities/admin/admin.constants';

const RemoveItemModal = ({ id, name, entity, onClose, opened }: IRemoveItemModalProps) => {

  const { deleting } = useAppSelector(selectAdminState);

  const dispatch = useAppDispatch();

  const onConfirmRemove = () => {
    dispatch(deleteItem({ itemId: id, entityName: entity }));
  }  

  return (
      <Dialog
        open={opened}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Удаление ${ENTITY_TITLE_ROD[entity]}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              deleting[entity] === "succeeded" 
              ? `${ENTITY_TITLE[entity]} успешно удалён`
              : `Подтвердите удаление ${ENTITY_TITLE_ROD[entity]} "${name}"`
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
          {deleting[entity] === "succeeded" ? 'Закрыть' : 'Отмена'}
          </Button>
          {deleting[entity] !== "succeeded" &&
            <Button onClick={onConfirmRemove} autoFocus>
              Удалить
            </Button>
          }
        </DialogActions>
      </Dialog>
  );
}

export default memo(RemoveItemModal);
