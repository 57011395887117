import { createAsyncThunk } from "@reduxjs/toolkit";
import { IGetWorkLevelListParams, IPostWorkLevelData, IWorkLevel } from "./workLevel.types";
import { AxiosError, AxiosResponse } from "axios";
import { TAppDispatch, TRootState } from "store";
import { URL_WORK_LEVEL, URL_WORK_LEVELS } from "./workLevel.api";
import apiCitizen from "helpers/apiCitizen";

export const getWorkLevelList = createAsyncThunk<
  AxiosResponse<IWorkLevel[]>,
  IGetWorkLevelListParams,
  {
    dispatch: TAppDispatch;
    rejectValue: AxiosError;
    state: TRootState;
  }
>(
  "workLevel/getWorkLevelList",
  async (params, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
        return (await apiCitizen.get(URL_WORK_LEVELS, {params})) as AxiosResponse<IWorkLevel[]>;
    } catch (error) {
        return rejectWithValue(error as AxiosError);
    } 
  },
);

export const createWorkLevel = createAsyncThunk<
  AxiosResponse<IWorkLevel>,
  IPostWorkLevelData,
  {
    dispatch: TAppDispatch;
    rejectValue: AxiosError;
    state: TRootState;
  }
>(
  "workLevel/createWorkLevel",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
        return (await apiCitizen.post(URL_WORK_LEVELS, data)) as AxiosResponse<IWorkLevel>;
    } catch (error) {
        return rejectWithValue(error as AxiosError);
    } 
  },
);

export const updateWorkLevel = createAsyncThunk<
  AxiosResponse<IWorkLevel>,
  { data: Partial<IPostWorkLevelData>, id: number},
  {
    dispatch: TAppDispatch;
    rejectValue: AxiosError;
    state: TRootState;
  }
>(
  "workLevel/updateWorkLevel",
  async ({data, id}, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const url = URL_WORK_LEVEL(id);
    try {
        return (await apiCitizen.patch(url, data)) as AxiosResponse<IWorkLevel>;
    } catch (error) {
        return rejectWithValue(error as AxiosError);
    } 
  },
);