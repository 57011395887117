import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IRegion, IRegionState } from "./region.types";
import { createRegion, getRegionList, updateRegion } from "./region.thunks";

export const getRegionListCases = (
    builder: ActionReducerMapBuilder<IRegionState>,
  ) => {
    builder.addCase(getRegionList.pending, state => {
      state.loading = "loading";
      state.error = null;
    });
    builder.addCase(getRegionList.fulfilled, (state, action) => {
      const regions = action?.payload?.data || [];      
      const items = action.meta.arg.withEmpty ? regions : regions?.filter(item => item?.users?.length > 0);

      state.items = items;
      state.itemsFiltered = items;
      state.rows = regions?.map((region: IRegion) => ({
        ...region,
        country: region?.country?.name,
      }));
      state.loading = "succeeded";
    });
    builder.addCase(getRegionList.rejected, (state, action) => {
      state.loading = "failed";
      state.error = action.payload?.message;
    });
  };

  export const createRegionCases = (
    builder: ActionReducerMapBuilder<IRegionState>,
  ) => {
    builder.addCase(createRegion.pending, state => {
      state.creating = "loading";
      state.error = null;
    });
    builder.addCase(createRegion.fulfilled, (state, action) => {
      state.creating = "succeeded";
    });
    builder.addCase(createRegion.rejected, (state, action) => {
      state.creating = "failed";
      state.error = action.payload?.message;
    });
  };

  export const updateRegionCases = (
    builder: ActionReducerMapBuilder<IRegionState>,
  ) => {
    builder.addCase(updateRegion.pending, state => {
      state.updating = "loading";
      state.error = null;
    });
    builder.addCase(updateRegion.fulfilled, (state, action) => {
      state.updating = "succeeded";
    });
    builder.addCase(updateRegion.rejected, (state, action) => {
      state.updating = "failed";
      state.error = action.payload?.message;
    });
  };  