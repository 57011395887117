import { useCallback, useState, memo } from 'react';
import { useAppDispatch, useAppSelector } from 'store';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import { createCitizen } from 'entities/citizen/citizen.thunks';
import { selectCitizenState } from 'entities/citizen/citizen.slice';
import { ICitizenCreateData } from 'entities/citizen/citizen.types';
import { selectCityState } from 'entities/city/city.slice';
import { ICity } from 'entities/city/city.types';
import { IEditDataModalProps } from 'pages/admin/types';

import styles from "../../AdminUsers.module.scss";

const CreateUserModal = ({ onClose, opened }: IEditDataModalProps<unknown>) => {
  const defaultData: ICitizenCreateData = { email: null, cityId: null };
  const [data, setData] = useState<ICitizenCreateData>(defaultData);  
  const { creating } = useAppSelector(selectCitizenState);
  const { items: cities } = useAppSelector(selectCityState);

  const dispatch = useAppDispatch();

  const onConfirmCreate = () => {
    if (data?.email) {
        dispatch(createCitizen(data));
    }
  }  

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setData(oldData => ({ ...oldData, [name]: value}));
  }, []);

  const handleCityChange = useCallback((e: SelectChangeEvent) => {
    setData(oldData => ({ ...oldData, cityId: e.target.value}));
  }, []);

  return (
      <Dialog
        open={opened}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Добавление пользователя"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              creating === "succeeded" 
              ? 'Пользователь успешно добавлен' 
              : 'Заполните обязательные поля'
            }
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name='email'
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            value={data?.email}
            onChange={handleChange}
          /> 
          <TextField
            autoFocus
            margin="dense"
            name="lastName"
            label="Фамилия"
            type="text"
            fullWidth
            variant="outlined"
            value={data?.lastName}
            onChange={handleChange}
          /> 
          <TextField
            autoFocus
            margin="dense"
            name="firstName"
            label="Имя"
            type="text"
            fullWidth
            variant="outlined"
            value={data?.firstName}
            onChange={handleChange}
          /> 
          <FormControl fullWidth margin="normal" variant='standard'>
            <span className={styles.selectLabel}>Город</span>
            <Select
              name="cityId"
              labelId='select-label-city'
              value={data?.cityId ? data?.cityId.toString() : null}
              fullWidth
              onChange={handleCityChange}
              variant='outlined'
              displayEmpty
            >
              <MenuItem value={null}>-- Не выбран --</MenuItem>
              {cities?.map((city: ICity) => <MenuItem value={city.value}>{city.name}</MenuItem>)}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
          {creating === "succeeded" ? 'Закрыть' : 'Отмена'}
          </Button>
          {creating !== "succeeded" &&
            <Button onClick={onConfirmCreate} autoFocus>
              Сохранить
            </Button>
          }
        </DialogActions>
      </Dialog>
  );
}

export default memo(CreateUserModal);
