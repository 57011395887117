import { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as d3 from "d3";

import {ReactComponent as Map} from "svg/world2.svg";
import styles from "./MapWorld.module.scss";
import { useAppSelector } from "store";

import { mapSelector } from "entities/map/map.slice";
import { selectCountryState } from "entities/country/country.slice";
import { selectCitizenState } from "entities/citizen/citizen.slice";

import { ICountry } from "entities/country/country.types";
import { IMapWorldProps } from "./types";

import { drawUser } from "draw/drawUser";
import { drawCount } from "draw/drawCount";

import { computeDimensions } from "helpers/computeDimensions";
import { getCenterOfDimensions } from "helpers/getCenterOfDimensions";
import { randomIntFromInterval } from "helpers/randomIntFromInterval";

const MapWorld = ({onPinClick, onPinUserClick}: IMapWorldProps) => {
    const refMap = useRef();
    const navigate = useNavigate();
    const {view} = useAppSelector(mapSelector);

    const { itemsFiltered: crt_countries } = useAppSelector(selectCountryState);
    const { itemsMerged: crt_users } = useAppSelector(selectCitizenState);

    const onCountryClick = useCallback((value) => {
        if (value === "RU") {
            navigate("/country/russia");
        }
    }, []);

    const resetMap = () => {
        const svg = d3.select("#svg_map");
        svg.selectAll(`path`).attr("class", null);
        svg.selectAll("g").remove();    
    };

    useEffect(() => {
        resetMap();
        if (refMap?.current) {
            const svg = d3.select("#svg_map");
            var defs = svg.append('svg:defs');

            crt_countries.forEach((country: ICountry) => {
                const path = svg.select(`#${country.value}`).on("click", () => onCountryClick(country.value));

                if (path) {
                    const dimensions = computeDimensions(path, country?.title);
                    path.attr("class", styles.crtCountry);
                    if (dimensions) {
                        const { x_center, y_center } = getCenterOfDimensions(dimensions);

                        if (view === "people") {
                            const country_users = crt_users?.filter(u => u?.city?.country?.value === country.value) || [];

                            country_users.forEach((user) => {
                                const offset_x = dimensions.width > 150 ? randomIntFromInterval(-100, 100) : 0;
                                const offset_y = dimensions.height > 100 ? randomIntFromInterval(-20, 80) : 0;

                                drawUser(svg, defs, x_center, y_center, offset_x, offset_y, user.email, user.initials, () => onPinUserClick(user));
                            });
                        } else if (view === "count" || view === "projects") {
                            drawCount(svg, x_center, y_center, country.value, country.users?.length, () => onPinClick(country.value));
                        }
                    }
                }
            });
        }
    }, [refMap, view, crt_countries, crt_users, onCountryClick, onPinUserClick, onPinClick]);   

    return (
        <div className={styles.container}>
            <Map className={styles.map} ref={refMap}/>
        </div>
    )
}

export default MapWorld;