import { useCallback, useState, memo } from 'react';
import { useAppDispatch, useAppSelector } from 'store';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import { IEditDataModalProps } from 'pages/admin/types';
import { IDeveloperGroup, IDeveloperGroupCreateData } from 'entities/developerGroup/developerGroup.types';
import { createDevGroup } from 'entities/developerGroup/developerGroup.thunks';
import { selectDevGroupState } from 'entities/developerGroup/developerGroup.slice';

const CreateDevGroupModal = ({ onClose, opened }: IEditDataModalProps<IDeveloperGroup>) => {
  const defaultData: IDeveloperGroupCreateData = { 
    name: null,
  };
  const [data, setData] = useState<IDeveloperGroupCreateData>(defaultData);  

  const { creating } = useAppSelector(selectDevGroupState);

  const dispatch = useAppDispatch();

  const onConfirmCreate = () => {
    if (data?.name) {
        dispatch(createDevGroup(data));
    }
  }  

  const handleDataChange = useCallback((e) => {
    setData(oldData => ({ ...oldData, [e.target.name]: e.target.value}));
  }, []);

  return (
      <Dialog
        open={opened}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Новая компетенция"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              creating === "succeeded" 
              ? 'Компетенция успешно добавлен' 
              : 'Заполните обязательные поля'
            }
          </DialogContentText>
          {creating !== "succeeded" && <>
            <TextField
              margin="normal"
              name="name"
              label="Наименование"
              fullWidth
              variant="outlined"
              value={data?.name}
              onChange={handleDataChange}
            /> 
          </>}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
          {creating === "succeeded" ? 'Закрыть' : 'Отмена'}
          </Button>
          {creating !== "succeeded" &&
            <Button onClick={onConfirmCreate} autoFocus>
              Сохранить
            </Button>
          }
        </DialogActions>
      </Dialog>
  );
}

export default memo(CreateDevGroupModal);
