import { drawPhotoPin } from "./drawPhotoPin";
import { drawPin } from "./drawPin";

export const drawUser = (svg, defs, x_center, y_center, offset_x, offset_y, email, text, onClick) => {
    const translate = `translate(${x_center-20+offset_x},${y_center-40+offset_y})`;
    const fileName = `${email?.split('@')?.[0]}.jpg`;
    
    import(`images/avatars/${fileName}`).then((response) => {
        drawPhotoPin(svg, defs, `user_img_${email}`, onClick, translate, response.default);
    }).catch(() => {
        drawPin(svg, `group_${email}`, onClick, translate, text);
    });
}