import { createSlice } from "@reduxjs/toolkit";
import { INITIAL_STATE } from "./stack.constants";
import { createStackCases, getStackListCases, updateStackCases } from "./stack.reducers";
import { TRootState } from "store";

const stackSlice = createSlice({
    initialState: INITIAL_STATE,
    name: "stack",
    reducers: {
        setCreatingState(state, action) {
            state.creating = action.payload;
        },
        setUpdatingState(state, action) {
            state.updating = action.payload;
        }
    },
    extraReducers(builder) {
        getStackListCases(builder);
        createStackCases(builder);
        updateStackCases(builder);
    },
});

export const {
    setCreatingState,
    setUpdatingState,
} = stackSlice.actions;

export default stackSlice.reducer;
export const selectStackState = (state: TRootState) => state.stack;