import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { INITIAL_STATE } from "./admin.constants";
import { deleteItemCases } from "./admin.reducers";
import { TRootState } from "store";
import { IPayloadDeletingState } from "./admin.types";

const adminSlice = createSlice({
    initialState: INITIAL_STATE,
    name: "admin",
    reducers: {
        setDeletingState(state, action: PayloadAction<IPayloadDeletingState>) {
            state.deleting[action.payload.entity] = action.payload.value;
        },
    },
    extraReducers(builder) {
        deleteItemCases(builder);
    },
});

export const {
    setDeletingState,
} = adminSlice.actions;

export default adminSlice.reducer;
export const selectAdminState = (state: TRootState) => state.admin;