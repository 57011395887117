export const computeDimensions = (selection: d3.Selection<d3.BaseType, unknown, HTMLElement, any>, name?: string): DOMRect => {
    var dimensions: DOMRect = null;
    var node = selection.node();
    
    try {
        if (node instanceof SVGGraphicsElement) {
            dimensions = node.getBBox();
        }
    } catch (e) {
        console.error('An error happend on: ', name || "some part of world")
    }
    return dimensions;
};