import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { TAppDispatch, TRootState } from "store";
import { IStack, IStackCreateData } from "./stack.types";
import { URL_STACK, URL_STACKS } from "./stack.api";
import apiCitizen from "helpers/apiCitizen";

export const getStackList = createAsyncThunk<
  AxiosResponse<IStack[]>,
  void,
  {
    dispatch: TAppDispatch;
    rejectValue: AxiosError;
    state: TRootState;
  }
>(
  "stack/getStackList",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
        return (await apiCitizen.get(URL_STACKS)) as AxiosResponse<IStack[]>;
    } catch (error) {
        return rejectWithValue(error as AxiosError);
    } 
  },
);

export const createStack = createAsyncThunk<
  AxiosResponse<IStack>,
  IStackCreateData,
  {
    dispatch: TAppDispatch;
    rejectValue: AxiosError;
    state: TRootState;
  }
>(
  "stack/createStack",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
        return (await apiCitizen.post(URL_STACKS, data)) as AxiosResponse<IStack>;
    } catch (error) {
        return rejectWithValue(error as AxiosError);
    } 
  },
);

export const updateStack = createAsyncThunk<
  AxiosResponse<IStack>,
  { data: IStackCreateData, id: number},
  {
    dispatch: TAppDispatch;
    rejectValue: AxiosError;
    state: TRootState;
  }
>(
  "stack/updateStack",
  async ({data, id}, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const url = URL_STACK(id);
    try {
        return (await apiCitizen.patch(url, data)) as AxiosResponse<IStack>;
    } catch (error) {
        return rejectWithValue(error as AxiosError);
    } 
  },
);