import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { TAppDispatch, TRootState } from "store";
import { IDeveloperGroup, IDeveloperGroupCreateData } from "./developerGroup.types";
import { URL_DEV_GROUP, URL_DEV_GROUPS } from "./developerGroup.api";
import apiCitizen from "helpers/apiCitizen";

export const getDevGroupList = createAsyncThunk<
  AxiosResponse<IDeveloperGroup[]>,
  void,
  {
    dispatch: TAppDispatch;
    rejectValue: AxiosError;
    state: TRootState;
  }
>(
  "devGroup/getDevGroupList",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
        return (await apiCitizen.get(URL_DEV_GROUPS)) as AxiosResponse<IDeveloperGroup[]>;
    } catch (error) {
        return rejectWithValue(error as AxiosError);
    } 
  },
);

export const createDevGroup = createAsyncThunk<
  AxiosResponse<IDeveloperGroup>,
  IDeveloperGroupCreateData,
  {
    dispatch: TAppDispatch;
    rejectValue: AxiosError;
    state: TRootState;
  }
>(
  "devGroup/createDevGroup",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
        return (await apiCitizen.post(URL_DEV_GROUPS, data)) as AxiosResponse<IDeveloperGroup>;
    } catch (error) {
        return rejectWithValue(error as AxiosError);
    } 
  },
);

export const updateDevGroup = createAsyncThunk<
  AxiosResponse<IDeveloperGroup>,
  { data: IDeveloperGroupCreateData, id: number},
  {
    dispatch: TAppDispatch;
    rejectValue: AxiosError;
    state: TRootState;
  }
>(
  "devGroup/updateDevGroup",
  async ({data, id}, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const url = URL_DEV_GROUP(id);
    try {
        return (await apiCitizen.patch(url, data)) as AxiosResponse<IDeveloperGroup>;
    } catch (error) {
        return rejectWithValue(error as AxiosError);
    } 
  },
);