import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { INITIAL_STATE } from "./profile.constants";
import { TAppDispatch, TRootState } from "store";
import { AxiosError, AxiosResponse } from "axios";
import api from "helpers/api";
import { ResponseError } from "entities/common/common.types";
import { IProfileState } from "./profile.types";

export const getMeThunk = createAsyncThunk<
  AxiosResponse,
  string,
  {
    dispatch: TAppDispatch;
    rejectValue: AxiosError;
    state: TRootState;
  }
>("profile/getMeThunk", async (slug, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const url = `/users/${slug}`;
    return (await api.get(url)) as AxiosResponse;
  } catch (error) {
    return rejectWithValue(error as AxiosError<ResponseError>);
  }
});

const getMeThunkCases = (builder: ActionReducerMapBuilder<IProfileState>) => {
  builder.addCase(getMeThunk.pending, (state, action) => {
    state.loading = 'loading';
    state.error = null;
  });
  builder.addCase(getMeThunk.fulfilled, (state, action) => {
    state.loading = 'succeeded';
    state.me = action.payload.data;
  });
  builder.addCase(getMeThunk.rejected, (state, action) => {
    state.loading = 'failed';
    const resp = action.payload?.response;
    state.error =
      resp?.['hydra:description'] ?? 'Неверно введён логин или пароль.';
  });
};

const profileSlice = createSlice({
  initialState: INITIAL_STATE,
  name: "profile",
  reducers: {},
  extraReducers: (builder) => {
    getMeThunkCases(builder);
  }
});

export const profileSelector = (state: TRootState) => state.profile;

export default profileSlice.reducer;