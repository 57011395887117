import { ChangeEvent, useCallback, useState } from "react";
import { DEFAULT_PAGE, DEFAULT_ROWS_PER_PAGE } from "./constants";

const useTablePagination = () => {
    const [page, setPage] = useState(DEFAULT_PAGE);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

    const handleChangePage = useCallback((event: unknown, newPage: number) => {
        setPage(newPage);
    }, []);

    const handleChangeRowsPerPage = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }, []);

    return {
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
    }
}

export default useTablePagination;